import React, { useLayoutEffect, useState, useEffect } from "react";
import emailjs from "emailjs-com";
// Removed axios import since it's no longer needed
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Footer from "./AASAFooter";
import SecondLogo from "../../images/AASA Logos Color/AASA_logo.png";
import NavMoASSPOct28 from "./NavAASA";
import TeachAIpolicy from "../../images/TeachAIpolicy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import TeachingAndLearning from "../../images/TeachingAndLearning.png";
import HumanResources from "../../images/HumanResources.png";
import BusinessAndOperations from "../../images/BusinessAndOperations.png";
import CommunicationsAndMarketing from "../../images/CommunicationsAndMarketing.png";
import ageOfLearning from "../../images/AofL_LG-endorsement_ABCcolor_stacked_RGB.jpg";
import audioEnhancement from "../../images/AudioEnhancement.png";
import google from "../../images/Google_Logo.png";
import magicAI from "../../images/MagicAI.png";
import magmaMath from "../../images/MagmaMath Vertical black10x (1).png";
import thoughtExchangeAI from "../../images/ThoughtExchangeAI (1).png";
import HackathonRoomIcon from "../../images/HackathonRoomIcon.png";
import PolicyAndGovernanceIcon from "../../images/PolicyAndGovernanceIcon.png";
import AISparkSessionsIcon from "../../images/AISparkSessionIcon.png";
import SharkShowcaseIcon from "../../images/SharkShowcaseIcon.png";
import PartnerPresentationsIcon from "../../images/PartnerPresentationsIcon.png";
import AllResources from "../../images/AllResources.png";
import GruvyLogo from "../../images/WhiteIcon_While_PurpleBG.png";

const AASAHome = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Add empty dependency array here

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showPopup]);

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm(
      "service_1g4hajp",
      "template_fjby2xf",
      e.target,
      "user_HAMTDSUiL200UN9RQyPHI"
    );
    document.getElementById("success").style.display = "flex";
    document.getElementById("contactForm").style.display = "none";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      // Send the email via EmailJS
      await emailjs.send(
        "service_1g4hajp", // Your EmailJS service ID
        "template_vw2orr8", // Your EmailJS template ID
        { user_email: email }, // Data to send to the template
        "user_HAMTDSUiL200UN9RQyPHI" // Your EmailJS user/public key
      );

      setSuccessMessage(
        "Resources have been sent to your email! Please check your spam folder if you don't see it."
      );
      setEmail("");
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "Uh oh. Something didn't work. Please email william.grube@gruvyeducation.com and let me know :)"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  function CopyToClipboard({ textToCopy, displayText }) {
    const [isCopied, setIsCopied] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const handleCopy = (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(textToCopy).then(() => {
        setIsCopied(true);
        setShowNotification(true);

        // Hide the notification after 1 second
        setTimeout(() => {
          setShowNotification(false);
        }, 1000);

        // Reset the isCopied state after 2 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      });
    };

    return (
      <div>
        <div className="textToCopy" onClick={handleCopy}>
          {displayText}
          <span className={`copy-icon ${isCopied ? "copied" : ""}`}>
            {isCopied ? "✓" : "📋"}
          </span>
        </div>
        {showNotification && (
          <div className="copy-notification">Text Copied!</div>
        )}
      </div>
    );
  }

  return (
    <body>
      {showScrollToTop && (
        <button
          className="MoASSP-scroll-to-top AASA-scroll-to-top"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          aria-label="Scroll to top"
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
      <div className="holder">
        <NavMoASSPOct28 module="teaching" />
        <section className="section-one bg-ghost-white lessPaddingTop">
          <header className="aasaHomeHeader"></header>
          {/* <div className="summitTitle">
            <div className="summitTitleWrapper">
              <img src={SecondLogo} alt="SecondLogo" />
              <h4>
                Explore resources from the AASA AI Super Summit 1.0, hosted by
                AASA, The School Superintendents Association, on January 30-31,
                2025, in Tampa, FL. Learn more about AASA at <a target="_blank" href="https://www.aasa.org/">aasa.org.</a>
              </h4>
            </div>
          </div> */}
          <div className="course-container">
            <div className="MoASSP-course-navbar">
              <ScrollLink
                activeClass="active"
                to="resources"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
                className="MoASSP-course-nav-item AASA-course-nav-item"
              >
                <div>
                  Summit
                  Resources
                </div>
              </ScrollLink>
              <a
                activeClass="active"
                href="https://www.aasa.org/docs/default-source/focus-groups/ai-super-summit-agenda.pdf"
                target="_blank"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
                className="MoASSP-course-nav-item AASA-course-nav-item"
              >
                <div>
                  Summit Agenda
                </div>
              </a>
              <ScrollLink
                activeClass="active"
                to="rupak-section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={50}
                className="MoASSP-course-nav-item AASA-course-nav-item"
              >
                <div>
                  Continued Learning
                </div>
              </ScrollLink>
            </div>
            <section name="resources" className="MoASSP-course-section">
              <h2 className="MoASSP-course-sub-title AASA-course-sub-title aiSupperSummitColor">
                Summit Resources
              </h2>
              <div className="MoASSP-tool-grid">
                <Link
                  to="/AASAteachingandlearning"
                  className="MoASSP-tool-card AASA-tool-card"
                >
                  <div className="MoASSP-tool-icon">
                    <img
                      src={TeachingAndLearning}
                      alt="Gruvy Education's Guide to Data Privacy With AI"
                    />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Teaching and Learning Track Resources
                  </h3>
                </Link>
                <Link to="/AASAHumanResources" className="MoASSP-tool-card AASA-tool-card">
                  <div className="MoASSP-tool-icon">
                    <img src={HumanResources} alt="ChatGPT" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Human Resources Track Resources
                  </h3>
                </Link>
                <Link
                  to="/AASABusinessAndOperations"
                  className="MoASSP-tool-card AASA-tool-card"
                >
                  <div className="MoASSP-tool-icon">
                    <img
                      src={BusinessAndOperations}
                      alt="Gruvy Education's Guide to Data Privacy With AI"
                    />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Business and Operations Track Resources
                  </h3>
                </Link>
                <Link
                  to="/AASACommunicationsMarketingAndSafety"
                  className="MoASSP-tool-card AASA-tool-card"
                >
                  <div className="MoASSP-tool-icon">
                    <img
                      src={CommunicationsAndMarketing}
                      alt="Gruvy Education's Guide to Data Privacy With AI"
                    />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Communications, Marketing & Safety Track Resources
                  </h3>
                </Link>
                <Link to="/AASAPolicyAndGovernance" className="MoASSP-tool-card AASA-tool-card">
                  <div className="MoASSP-tool-icon">
                    <img src={PolicyAndGovernanceIcon} alt="Policy and Governance Resources" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                  Policy and Change Managment Track Resources
                  </h3>
                </Link>
                <Link to="/AASAHackathonRoom" className="MoASSP-tool-card AASA-tool-card">
                  <div className="MoASSP-tool-icon">
                    <img src={HackathonRoomIcon} alt="Hackathon Room Resources" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Hackathon Room Resources
                  </h3>
                </Link>
                <Link to="/AASAAISparkSessions" className="MoASSP-tool-card AASA-tool-card">
                  <div className="MoASSP-tool-icon">
                    <img src={AISparkSessionsIcon} alt="AI Spark Sessions Resources" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    AI Spark Sessions Resources
                  </h3>
                </Link>
                {/* <Link to="/AASASharkShowcaseSession" className="MoASSP-tool-card AASA-tool-card">
                  <div className="MoASSP-tool-icon">
                    <img src={SharkShowcaseIcon} alt="Shark Showcase Session Resources" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Shark Showcase Session Resources
                  </h3>
                </Link> */}
                {/* <Link
                  to="/AASAPartnerPresentations"
                  className="MoASSP-tool-card AASA-tool-card"
                >
                  <div className="MoASSP-tool-icon">
                    <img src={PartnerPresentationsIcon} alt="Partner Presentations Resources" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                    Partner Presentations
                  </h3>
                </Link> */}
                <Link
                  to="/AASAAllResources"
                  className="MoASSP-tool-card AASA-tool-card"
                >
                  <div className="MoASSP-tool-icon">
                    <img src={AllResources} alt="Partner Presentations Resources" />
                  </div>
                  <h3 className="MoASSP-tool-title AASA-tool-title">
                  Complete Conference Resources and Many Extras
                  </h3>
                </Link>
              </div>
            </section>

            <section name="rupak-section" className="MoASSP-course-section aiSupperSummitColor">
              <h2 className="MoASSP-course-sub-title AASA-course-sub-title aiSupperSummitColor">Continued Learning: Full AI Course</h2>
              <div className="MoASSP-single-tool-grid">
                <a
                  href="https://gruvyeducation.com/training/Extended%20Learning:%20AASA%20AI%20Super%20Summit"
                  target="_blank"
                  className="MoASSP-single-tool-card AASA-single-tool-card"
                >
                  <div className="MoASSP-single-tool-icon">
                    <img src={GruvyLogo} alt="TeachAI" />
                  </div>
                  <h3 className="MoASSP-single-tool-title AASA-single-tool-title">
                    Gruvy Education AI Training for School Leaders
                  </h3>
                  <p className="MoASSP-single-tool-text AASA-single-tool-text">
                    You have been granted free access into Gruvy Education's AI Training for School Leaders.
                  </p>
                </a>
                
              </div>
            </section>
          </div>
        </section>
        <section id="aiSuperSUmmitPartners">
          <div className="aiSuperSUmmitWrapper">
            <h1 className="heading">
              Thank you to the AI Super Summit Partners
            </h1>
            <div className="aisupersummitcards">
              {[
                ageOfLearning,
                audioEnhancement,
                google,
                magicAI,
                magmaMath,
                thoughtExchangeAI,
              ].map((item, index) => (
                <img key={index} src={item} alt="AI Super Summit Partners" />
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </body>
  );
};

export default AASAHome;