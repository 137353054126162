import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo-no-background.png";

const FFNavbar = () => {
  // State to track scroll position for fixed header
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Handle scroll event to apply fixed header class
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle contact link click - scroll to footer contact form
  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      // Close mobile menu if open
      setMobileMenuOpen(false);
      
      // Smooth scroll to contact section
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header id="ffNav" className={`ffNav ff-font ff-header ${isScrolled ? "ff-header-fixed" : ""}`}>
      <div className="ff-container">
        <div className="ff-header-content">
          <div className="ff-logo-container">
            <Link to="/">
              <img src={Logo} alt="Gruvy Education Logo" className="ff-logo" />
            </Link>
          </div>
          
          {/* Mobile Menu Button */}
          <button 
            className={`ff-mobile-menu-btn ${mobileMenuOpen ? 'ff-menu-open' : ''}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          
          <nav className={`ff-nav ${mobileMenuOpen ? 'ff-nav-mobile-open' : ''}`}>
            <div className="ff-dropdown">
              <span className="ff-dropdown-btn ff-nav-link">
                Training
                <span className="ff-dropdown-arrow">▼</span>
              </span>
              <div className="ff-dropdown-content">
                <Link to="/traininginfo" className="ff-dropdown-item">
                  Training Info
                </Link>
                <Link to="/training" className="ff-dropdown-item">
                  Training Portal
                </Link>
              </div>
            </div>
            <Link to="/testimonials" className="ff-nav-link">
              Proof
            </Link>
            <Link to="/about" className="ff-nav-link">
              About
            </Link>
            <Link to="/freeresources" className="ff-nav-link">
              Free Stuff
            </Link>
            <a href="#contact" onClick={scrollToContact} className="ff-nav-link ff-cta">
              Contact Us
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default FFNavbar;