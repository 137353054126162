import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './MESPAFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavMESPA from './NavMESPA';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import Modification from '../../images/Modification.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
import Elementary from '../../images/Elementary.png'
import Firefly from '../../images/Firefly.png'
import Slide from '../../images/Slide.png'
import Sheet from '../../images/GoogleSheetIcon.png'
const MESPA = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavMESPA module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">
                                AI Skills for School Leaders
                            </div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="activities"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Roadmap">🗺️🚀</span> Session Roadmap
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>
                        </div>
                        <section id="activities" className="MoASSP-course-section MoASSP-activities-section">
                            <h2 className="MoASSP-course-sub-title">🗺️🚀 Roadmap</h2>
                            <div className="MoASSP-activities-wrapper">
                                <div className="MoASSP-activity-nav-bar">
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="1"
                                        className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer"
                                    >
                                        1
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="2"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        2
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="3"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        3
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="4"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        4
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="5"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        5
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="6"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        6
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="7"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        7
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="8"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        8
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="9"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        9
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="10"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        10
                                    </ScrollLink>
                                    <ScrollLink
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50}
                                        to="11"
                                        className="MoASSP-activity-nav-item cursorPointer"
                                    >
                                        11
                                    </ScrollLink>
                                </div>

                                {/* Activity 1: Introduction to ChatGPT in Communications */}
                                <div key="1" id="1" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 1: </span>
                                        Introduction to ChatGPT in Communications
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Send this prompt to ChatGPT: "As a school principal, create a survey to gather feedback from the teachers in my school on the issues they have encountered at the beginning of the school year."
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Send this prompt to ChatGPT: "Now, generate an email that sends this form out to teachers. Use simple, straightforward wording. Encourage candid responses because I genuinely care and want to know how I can help. Tell them that this must be completed by Friday, November 23rd, at noon."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 2: Learn How to Design Effective ChatGPT Prompts */}
                                <div key="2" id="2" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 2: </span>
                                        Learn How to Design Effective ChatGPT Prompts with the Role, Action, Context, Format Framework
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={Guide3} alt="Education Prompt Framework Guide" />
                                                    <span className="MoASSP-link-label">Prompt Framework Guide</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Use this example: "Act as a school principal. (Role) Draft a student-friendly Classroom Device Care Agreement that is easy for students to understand and follow. (Action) Include clear expectations, consequences for non-compliance, and positive reinforcement techniques. (Context) Ensure the contract is straightforward and has a spot for a student signature. (Format)." Design your own prompt using the Role, Action, Context, Format Framework.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 3: Navigating Difficult Parent Emails with ChatGPT */}
                                <div key="3" id="3" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 3: </span>
                                        Navigating Difficult Parent Emails with ChatGPT
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Use this prompt in ChatGPT: "Act as a [Position e.g., Principal, Teacher]. You've received an email from a parent who is visibly upset or angry about [Issue]. Please draft a tactful, empathetic, and professional email that acknowledges the parent's feelings, clarifies any misunderstandings, and outlines steps the school is taking or will take to address the issue. Also, if appropriate, suggest a follow-up meeting or phone call to discuss the matter further. Include the following points: [what you would like to include]."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 4: Using ChatGPT to Summarize a Document */}
                                <div key="4" id="4" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 4: </span>
                                        Using ChatGPT to Summarize a Document
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/12y5ISvMMfvchn_zQj75-B6plGFY5GoLfy-XDYfxZ1wk/edit?tab=t.0#heading=h.hykksdagzdjr"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={Doc} alt="Document" />
                                                    <span className="MoASSP-link-label">Document</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the document. Click "File," hover over "Download," and select "Microsoft Word (.docx)". Then, type "Done" below.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Open ChatGPT, click the paperclip icon, upload your file, and send this prompt with the document: "Summarize this school policy into a clear, concise overview highlighting the key points and action items. Tailor the summary for a school leader to use in decision-making." Paste the output below.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 5: Utilizing AI for Crafting Student Intervention Strategies */}
                                <div key="5" id="5" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 5: </span>
                                        Utilizing AI for Crafting Student Intervention Strategies
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Use this prompt in ChatGPT: "You're the [position, e.g., 'principal', 'school counselor'] and need to develop an intervention strategy for a student facing challenges in [Challenge Areas]. Create a plan that specifies intervention types such as academic support, behavioral coaching, or external resources. Ensure the strategy aligns with [School Goals] and includes criteria for success over a specific [Timeframe]. Also, outline how to involve teachers, parents, and counselors."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 6: Developing Student Behavior Plans */}
                                <div key="6" id="6" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 6: </span>
                                        Developing Student Behavior Plans
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Use this prompt in ChatGPT: "As a principal, develop a behavior plan for a student who has shown [Behavioral Issues]. Include objectives, behavior expectations, monitoring methods, and potential interventions or rewards. Ensure the plan aligns with [School Policies] and is set for a [Timeframe]. Also, consider how to involve teachers, parents, and staff."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 7: IEP Goals and Objectives */}
                                <div key="7" id="7" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 7: </span>
                                        IEP Goals and Objectives
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Use this prompt in ChatGPT: "As a [State e.g. MN] special education teacher working with a [grade] student on [skill area], I need IEP goal ideas that focus on improving these skills: [insert skills]. Include the direction of change, the skill or behavior to be changed, and the expected annual performance. Write each goal as one sentence."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 8: Leveraging ChatGPT for Expert-Free Feedback */}
                                <div key="8" id="8" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 8: </span>
                                        Leveraging ChatGPT for Expert-Free Feedback: Coaching AP Physics Instruction
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Use this prompt in ChatGPT: "Act as if you are a high school principal coaching a teacher on their instruction. Though you lack AP Physics content expertise, note that the teacher struggles with aligning lessons to standards and measurable outcomes. Provide notes for teacher evaluations and suggestions for coaching conversations."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 9: Identifying Students for Support with Data Analysis */}
                                <div key="9" id="9" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 9: </span>
                                        Identifying Students for Support with Data Analysis
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?tab=t.0#heading=h.n8ca5v8bstpe"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={Modification} alt="Data Anonymization Guide" />
                                                    <span className="MoASSP-link-label">Data Anonymization Guide</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/spreadsheets/d/1d979Yw7XzGoCSGQ1f-24uNVuVU_dHFBQPDZwpbxTNgE/edit?gid=0#gid=0"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={Sheet} alt="Mock Data" />
                                                    <span className="MoASSP-link-label">Mock Data</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the Google Sheet. Click "File" and then "Make a Copy." Anonymize the data as instructed in the provided guide.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Open ChatGPT, click the paperclip icon, upload your Excel file, and send this prompt with the file: "Analyze the attached assessment data to identify students who may be candidates for specialized programs. Consider scores below grade-level, lack of progress, and discrepancies between subjects. Provide a list of candidates with supporting data and recommend interventions. Use the Anonymized IDs."
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 10: Creating a Custom GPT: Quick-Start Guide */}
                                <div key="10" id="10" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 10: </span>
                                        Creating a Custom GPT: Quick-Start Guide
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/1nmUKCCxU8vrCKpGbLhhv--Hz3po5x7rfEDgqVunNVyc/edit?tab=t.0#heading=h.xx3t6lbinwe8"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img className="MoASSP-link-icon-img" src={Guide2} alt="Custom GPT Guide" />
                                                    <span className="MoASSP-link-label">Custom GPT Guide</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the document and follow the steps to create a custom GPT. In the box below, describe what your custom GPT does. Consider a GPT that writes teacher observations in your style.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Activity 11: How Teachers Can Use AI in the Classroom */}
                                <div key="11" id="11" className="MoASSP-course-content MoASSP-activity-content">
                                    <hr className="MoASSP-activity-separator" />
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 11: </span>
                                        How Teachers Can Use AI in the Classroom
                                    </h3>
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/1hBqQmZhdoMb_P77VMz_jkrPd1cexcd8Hm0C7rU1qsg8/edit?tab=t.0"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={Elementary}
                                                        alt="Using AI as an Early Elementary Educator"
                                                    />
                                                    <span className="MoASSP-link-label">ChatGPT Guide for Early Elementary Classroom Content</span>
                                                </a>
                                                <a
                      className="MoASSP-link-icon"
                      href="https://docs.google.com/document/d/1XqypS1cXLf2RuOxnzfMfJavO7-3zj_KJCqR4_FIefnI/edit?tab=t.0#heading=h.2emy8rl7g2li"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="MoASSP-link-icon-img" src={Modification} alt="Assignment Modification" />
                      <span className="MoASSP-link-label">Assignment Modification</span>
                    </a>
                    <a
                      className="MoASSP-link-icon"
                      href="https://docs.google.com/document/d/1pQZdVMrMs7CD88sqNlNQk7mbRf133heuSGz9lakBE4Y/edit?tab=t.0#heading=h.daat8d9nyw8n"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img className="MoASSP-link-icon-img" src={Guide1} alt="Classroom Content Creation" />
                      <span className="MoASSP-link-label">Classroom Content</span>
                    </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/18ylptbWgxiEUkgVMOtMW5Xa0oGMD6L7jzXKsPTt0G7I/edit?tab=t.0#heading=h.d4h5j8hp0cvx"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={Magic1}
                                                        alt="How to Use MagicSchool"
                                                    />
                                                    <span className="MoASSP-link-label">How to Use MagicSchool</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/1pzzLJeGkwmN1JQTRVhGXiVoareH6EufTozjtZ6O5I98/edit?tab=t.0#heading=h.kz09ikzaz6rm"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={Magic2}
                                                        alt="MagicStudent with Students"
                                                    />
                                                    <span className="MoASSP-link-label">MagicStudent with Students</span>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Review the links to learn how AI supports classroom planning,
                                                content creation, and student engagement. Adapt these ideas to fit your teaching style.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body>

    );
}

export default MESPA;
