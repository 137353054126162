import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FFNavbar from "./FFNavbar";
import FFFooter from "./FFFooter";
import ResourceCard from "./ResourceCard";
import VideoCard from "./VideoCard";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  doc,
  updateDoc,
  increment,
  getDoc,
} from "firebase/firestore";
import db from "../../Firebase";
import Doc from "../../images/doc.png";

// Define collection names
const COLLECTIONS = {
  LEADER_RESOURCES: "leaderResources",
  TEACHER_RESOURCES: "teacherResources",
  VIDEO_RESOURCES: "videoResources",
};

const FFResources = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  // State to track the active tab - initialize from URL hash if available
  const [activeTab, setActiveTab] = useState(() => {
    // Get the hash from URL without the # symbol
    const hash = window.location.hash.substring(1);

    // Valid tab names
    const validTabs = [
      "leaders",
      "teachers",
      "videos",
      "prompts",
      "newsletter",
    ];

    // Return the hash if it's a valid tab name, otherwise default to "leaders"
    return validTabs.includes(hash) ? hash : "leaders";
  });

  // State for resources
  const [leaderResources, setLeaderResources] = useState([]);
  const [teacherResources, setTeacherResources] = useState([]);
  const [videoResources, setVideoResources] = useState([]);

  // Add states for prompt library
  const [prompts, setPrompts] = useState([]);
  const [categories, setCategories] = useState(["All Prompts"]);
  const [selectedCategory, setSelectedCategory] = useState(() => {
    // Check if URL contains a category parameter
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get("category");
    return category || "All Prompts";
  });

  useEffect(() => {
    // Get the hash from URL without the # symbol
    const hash = window.location.hash.substring(1);
    const validTabs = [
      "leaders",
      "teachers",
      "videos",
      "prompts",
      "newsletter",
    ];

    // Determine which resources to load based on current hash
    if (!hash || hash === "leaders" || !validTabs.includes(hash)) {
      console.log("Initial fetch of leader resources");
      fetchLeaderResources();
    }

    if (hash === "teachers") {
      console.log("Initial fetch of teacher resources");
      setLoading((prev) => ({ ...prev, teachers: true }));
      fetchTeacherResources();
    }

    if (hash === "videos") {
      console.log("Initial fetch of video resources");
      setLoading((prev) => ({ ...prev, videos: true }));
      fetchVideoResources();
    }

    if (hash === "prompts") {
      console.log("Initial fetch of prompts");
      setLoading((prev) => ({ ...prev, prompts: true }));
      fetchPrompts();
    }

    // Clean up function
    return () => {
      // Optional: Add cleanup if needed
    };
  }, []);

  // Loading states
  const [loading, setLoading] = useState({
    leaders: true,
    teachers: false,
    videos: false,
    prompts: false,
  });

  // Error states
  const [errors, setErrors] = useState({
    leaders: null,
    teachers: null,
    videos: null,
    prompts: null,
  });

  // Function to handle tab switching
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);

    // Update the URL hash without triggering a page reload
    window.history.pushState(null, "", `#${tabName}`);

    // Load resources for that tab if not already loaded
    if (tabName === "leaders" && leaderResources.length === 0) {
      setLoading((prev) => ({ ...prev, leaders: true }));
      fetchLeaderResources();
    } else if (tabName === "teachers" && teacherResources.length === 0) {
      setLoading((prev) => ({ ...prev, teachers: true }));
      fetchTeacherResources();
    } else if (tabName === "videos" && videoResources.length === 0) {
      setLoading((prev) => ({ ...prev, videos: true }));
      fetchVideoResources();
    } else if (tabName === "prompts" && prompts.length === 0) {
      setLoading((prev) => ({ ...prev, prompts: true }));
      fetchPrompts();
    }
  };

  // Function to track resource clicks
  const trackResourceClick = async (collectionName, docId) => {
    try {
      const resourceRef = doc(db, collectionName, docId);
      await updateDoc(resourceRef, {
        clickCount: increment(1),
      });
      console.log(`Tracked click for ${docId} in ${collectionName}`);
      return true;
    } catch (error) {
      console.error("Error tracking resource click:", error);
      return false;
    }
  };

  // Function to fetch prompts from Firestore
  const fetchPrompts = async () => {
    try {
      console.log("Fetching prompts directly from Firestore...");

      // Get categories
      const categoriesDoc = await getDoc(doc(db, "Prompts", "000categories"));
      if (categoriesDoc.exists()) {
        const categoriesData = categoriesDoc.data();
        // Extract categories and sort them alphabetically
        const sortedCategories = Object.keys(categoriesData.AllCategories).sort(
          (a, b) => a.localeCompare(b)
        );
        // Prepend 'All Prompts' to the sorted categories
        setCategories(["All Prompts", ...sortedCategories]);
      }

      // Get prompts
      const promptsRef = collection(db, "Prompts");
      const promptsSnapshot = await getDocs(promptsRef);

      console.log("Prompts snapshot received:", promptsSnapshot);
      if (promptsSnapshot.empty) {
        console.log("No prompts found");
        setPrompts([]);
      } else {
        const promptsData = promptsSnapshot.docs
          .filter((doc) => doc.id !== "000categories") // Exclude the categories document
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

        console.log("Prompts:", promptsData);
        setPrompts(promptsData);
      }
    } catch (error) {
      console.error("Error fetching prompts:", error);
      setErrors((prev) => ({
        ...prev,
        prompts: error.message || "Failed to load prompts",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, prompts: false }));
    }
  };

  // Direct Firestore fetch for leader resources - simplified approach
  const fetchLeaderResources = async () => {
    try {
      console.log("Fetching leader resources directly from Firestore...");

      // Simplified query without the where clause
      const leaderQuery = query(collection(db, COLLECTIONS.LEADER_RESOURCES));

      const snapshot = await getDocs(leaderQuery);

      console.log("Snapshot received:", snapshot);
      if (snapshot.empty) {
        console.log("No leader resources found");
        setLeaderResources([]);
      } else {
        // Filter and sort client-side
        const resources = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            collection: COLLECTIONS.LEADER_RESOURCES,
          }))
          .filter((resource) => resource.active !== false) // Filter out inactive resources
          .sort((a, b) => {
            // Sort by order if available
            if (a.order !== undefined && b.order !== undefined) {
              return a.order - b.order;
            }
            return 0;
          });

        console.log("Leader resources:", resources);
        setLeaderResources(resources);
      }
    } catch (error) {
      console.error("Error fetching leader resources:", error);
      setErrors((prev) => ({
        ...prev,
        leaders: error.message || "Failed to load resources",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, leaders: false }));
    }
  };

  // Direct Firestore fetch for teacher resources - simplified approach
  const fetchTeacherResources = async () => {
    try {
      console.log("Fetching teacher resources directly from Firestore...");

      // Simplified query without the where clause
      const teacherQuery = query(collection(db, COLLECTIONS.TEACHER_RESOURCES));

      const snapshot = await getDocs(teacherQuery);

      console.log("Snapshot received:", snapshot);
      if (snapshot.empty) {
        console.log("No teacher resources found");
        setTeacherResources([]);
      } else {
        // Filter and sort client-side
        const resources = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            collection: COLLECTIONS.TEACHER_RESOURCES,
          }))
          .filter((resource) => resource.active !== false) // Filter out inactive resources
          .sort((a, b) => {
            // Sort by order if available
            if (a.order !== undefined && b.order !== undefined) {
              return a.order - b.order;
            }
            return 0;
          });

        console.log("Teacher resources:", resources);
        setTeacherResources(resources);
      }
    } catch (error) {
      console.error("Error fetching teacher resources:", error);
      setErrors((prev) => ({
        ...prev,
        teachers: error.message || "Failed to load resources",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, teachers: false }));
    }
  };

  // Direct Firestore fetch for video resources - simplified approach
  const fetchVideoResources = async () => {
    try {
      console.log("Fetching video resources directly from Firestore...");

      // Simplified query without the where clause
      const videoQuery = query(collection(db, COLLECTIONS.VIDEO_RESOURCES));

      const snapshot = await getDocs(videoQuery);

      console.log("Snapshot received:", snapshot);
      if (snapshot.empty) {
        console.log("No video resources found");
        setVideoResources([]);
      } else {
        // Filter and sort client-side
        const resources = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            collection: COLLECTIONS.VIDEO_RESOURCES,
          }))
          .filter((resource) => resource.active !== false) // Filter out inactive resources
          .sort((a, b) => {
            // Sort by order if available
            if (a.order !== undefined && b.order !== undefined) {
              return a.order - b.order;
            }
            return 0;
          });

        console.log("Video resources:", resources);
        setVideoResources(resources);
      }
    } catch (error) {
      console.error("Error fetching video resources:", error);
      setErrors((prev) => ({
        ...prev,
        videos: error.message || "Failed to load resources",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, videos: false }));
    }
  };

  // Initial fetch of resources for the default tab
  useEffect(() => {
    console.log("Initial fetch of leader resources");
    fetchLeaderResources();

    // Clean up function
    return () => {
      // Optional: Add cleanup if needed
    };
  }, []);

  const PromptCategoryDropdown = () => {
    const [isActive, setIsActive] = useState(false);

    const handleCategoryChange = (category) => {
      setSelectedCategory(category);
      setIsActive(false);

      // Update URL with category parameter
      const url = new URL(window.location);
      url.searchParams.set("category", category);
      window.history.pushState({}, "", url);
    };

    return (
      <div className="ff-prompt-dropdown-centered">
        <h3 className="ff-prompt-dropdown-label">Select a Prompt Category</h3>
        <div className="ff-dropdown-container">
          <button
            className={`ff-dropdown-button-large ${isActive ? "active" : ""}`}
            onClick={() => setIsActive(!isActive)}
          >
            {selectedCategory}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>

          {isActive && (
            <div className="ff-dropdown-menu-large">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className="ff-dropdown-item"
                  onClick={() => handleCategoryChange(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Debug log for rendered component
  useEffect(() => {
    console.log("Current state:", {
      activeTab,
      loading,
      errors,
      leaderResourcesCount: leaderResources.length,
      teacherResourcesCount: teacherResources.length,
      videoResourcesCount: videoResources.length,
    });
  }, [
    activeTab,
    loading,
    errors,
    leaderResources,
    teacherResources,
    videoResources,
  ]);

  return (
    <div className="ff-home ff-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Free AI Education Resources | Guides & Templates for K-12 Schools
        </title>
        <meta
          name="description"
          content="Downloadable AI policy guides, classroom tutorials, implementation roadmaps, and YouTube training videos. No signup required. Free resources for both leaders and teachers."
        />
        <meta
          name="keywords"
          content="free AI resources for teachers, school AI policy guides, ChatGPT education videos, teacher AI guides, K-12 AI implementation help"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Free AI Education Resources | Guides & Templates for K-12 Schools"
        />
        <meta
          property="og:description"
          content="Downloadable AI policy guides, classroom tutorials, implementation roadmaps, and YouTube training videos. No signup required."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://gruvyeducation.com/freeresources"
        />
      </Helmet>

      <FFNavbar />

      <section className="ff-page-header ff-resources-header">
        <div className="ff-container">
          <h1 className="ff-page-title">Free AI Resources</h1>
          <p className="ff-page-subtitle">
            Practical tools and guides to help your school implement AI
            effectively
          </p>
        </div>
      </section>

      <section className="ff-section ff-resources-main">
        <div className="ff-container">
          {/* Tabbed Navigation */}
          <div className="ff-resources-tabs">
            <button
              className={`ff-tab-button ${
                activeTab === "leaders" ? "active" : ""
              }`}
              onClick={() => handleTabChange("leaders")}
            >
              <span className="ff-tab-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 7h-3a2 2 0 0 1-2-2V2"></path>
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7"></path>
                  <path d="M14 2v3a2 2 0 0 0 2 2h3"></path>
                </svg>
              </span>
              School Leader Resources
            </button>

            <button
              className={`ff-tab-button ${
                activeTab === "teachers" ? "active" : ""
              }`}
              onClick={() => handleTabChange("teachers")}
            >
              <span className="ff-tab-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
                  <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
                  <line x1="6" y1="6" x2="6.01" y2="6"></line>
                  <line x1="6" y1="18" x2="6.01" y2="18"></line>
                </svg>
              </span>
              Teacher Resources
            </button>

            <button
              className={`ff-tab-button ${
                activeTab === "videos" ? "active" : ""
              }`}
              onClick={() => handleTabChange("videos")}
            >
              <span className="ff-tab-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polygon points="23 7 16 12 23 17 23 7"></polygon>
                  <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                </svg>
              </span>
              YouTube Videos
            </button>

            {/* Change this from a Link to a button */}
            <button
              className={`ff-tab-button ${
                activeTab === "prompts" ? "active" : ""
              }`}
              onClick={() => handleTabChange("prompts")}
            >
              <span className="ff-tab-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="8" y1="6" x2="21" y2="6"></line>
                  <line x1="8" y1="12" x2="21" y2="12"></line>
                  <line x1="8" y1="18" x2="21" y2="18"></line>
                  <line x1="3" y1="6" x2="3.01" y2="6"></line>
                  <line x1="3" y1="12" x2="3.01" y2="12"></line>
                  <line x1="3" y1="18" x2="3.01" y2="18"></line>
                </svg>
              </span>
              Prompt Library
            </button>

            <a
              className={`ff-tab-button ${
                activeTab === "newsletter" ? "active" : ""
              }`}
              href="https://direct-ai-updates-for-school-admins.beehiiv.com/"
              target="_blank"
            >
              <span className="ff-tab-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </span>
              Leadership Newsletter
            </a>
          </div>

          {/* Tab Content Containers */}
          <div className="ff-tab-content-container">
            {/* School Leaders Resources Tab */}
            <div
              className={`ff-tab-content ${
                activeTab === "leaders" ? "active" : ""
              }`}
              id="leaders-tab"
            >
              <h2 className="ff-section-title">School Leader Resources</h2>

              {loading.leaders ? (
                <div className="ff-resources-placeholder">
                  <div className="ff-loading-spinner"></div>
                  <p>Loading school leader resources...</p>
                </div>
              ) : errors.leaders ? (
                <div className="ff-resources-placeholder ff-error">
                  <p>Error: {errors.leaders}</p>
                  <button
                    onClick={fetchLeaderResources}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Try Again
                  </button>
                </div>
              ) : leaderResources.length > 0 ? (
                <div className="ff-resources-grid">
                  {leaderResources.map((resource) => (
                    <ResourceCard
                      key={resource.id}
                      {...resource}
                      onTrackClick={() =>
                        trackResourceClick(resource.collection, resource.id)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="ff-resources-placeholder">
                  <p>
                    No resources available at this time. Please check back soon!
                  </p>
                  <button
                    onClick={fetchLeaderResources}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Refresh
                  </button>
                </div>
              )}
            </div>

            {/* Teachers Resources Tab */}
            <div
              className={`ff-tab-content ${
                activeTab === "teachers" ? "active" : ""
              }`}
              id="teachers-tab"
            >
              <h2 className="ff-section-title">Teacher Resources</h2>

              {loading.teachers ? (
                <div className="ff-resources-placeholder">
                  <div className="ff-loading-spinner"></div>
                  <p>Loading teacher resources...</p>
                </div>
              ) : errors.teachers ? (
                <div className="ff-resources-placeholder ff-error">
                  <p>Error: {errors.teachers}</p>
                  <button
                    onClick={fetchTeacherResources}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Try Again
                  </button>
                </div>
              ) : teacherResources.length > 0 ? (
                <div className="ff-resources-grid">
                  {teacherResources.map((resource) => (
                    <ResourceCard
                      key={resource.id}
                      {...resource}
                      onTrackClick={() =>
                        trackResourceClick(resource.collection, resource.id)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="ff-resources-placeholder">
                  <p>
                    No resources available at this time. Please check back soon!
                  </p>
                  <button
                    onClick={fetchTeacherResources}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Refresh
                  </button>
                </div>
              )}
            </div>

            {/* YouTube Videos Tab */}
            <div
              className={`ff-tab-content ${
                activeTab === "videos" ? "active" : ""
              }`}
              id="videos-tab"
            >
              <h2 className="ff-section-title">YouTube Videos</h2>
              {loading.videos ? (
                <div className="ff-resources-placeholder">
                  <div className="ff-loading-spinner"></div>
                  <p>Loading videos...</p>
                </div>
              ) : errors.videos ? (
                <div className="ff-resources-placeholder ff-error">
                  <p>Error: {errors.videos}</p>
                  <button
                    onClick={fetchVideoResources}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Try Again
                  </button>
                </div>
              ) : videoResources.length > 0 ? (
                <div className="ff-videos-grid">
                  {videoResources.map((video) => (
                    <VideoCard
                      key={video.id}
                      {...video}
                      onTrackClick={() =>
                        trackResourceClick(video.collection, video.id)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="ff-resources-placeholder">
                  <p>
                    No videos available at this time. Please check back soon!
                  </p>
                  <button
                    onClick={fetchVideoResources}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Refresh
                  </button>
                </div>
              )}
            </div>

            {/* Prompt Library Tab */}
            <div
              className={`ff-tab-content ${
                activeTab === "prompts" ? "active" : ""
              }`}
              id="prompts-tab"
            >
              <h2 className="ff-section-title">Educator Prompt Library</h2>
              {loading.prompts ? (
                <div className="ff-resources-placeholder">
                  <div className="ff-loading-spinner"></div>
                  <p>Loading prompt library...</p>
                </div>
              ) : errors.prompts ? (
                <div className="ff-resources-placeholder ff-error">
                  <p>Error: {errors.prompts}</p>
                  <button
                    onClick={fetchPrompts}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Try Again
                  </button>
                </div>
              ) : prompts.length > 0 ? (
                <>
                  <div className="ff-prompt-controls-centered">
                    <PromptCategoryDropdown />
                  </div>
                  <div className="ff-prompts-grid">
                    {prompts
                      .filter(
                        (prompt) =>
                          prompt.Category === selectedCategory ||
                          selectedCategory === "All Prompts"
                      )
                      .sort((a, b) => {
                        if (a.Category < b.Category) return -1;
                        if (a.Category > b.Category) return 1;
                        return 0;
                      })
                      .map((prompt) => (
                        <a
                          key={prompt.id}
                          href={prompt.Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ff-prompt-card"
                        >
                          <div className="ff-prompt-content">
                            <div className="ff-prompt-title-wrapper">
                              <h3 className="ff-prompt-title">
                                {prompt.Title}
                              </h3>
                            </div>
                            <div className="ff-prompt-category-badge">
                              {prompt.Category}
                            </div>
                            <div className="ff-prompt-card-footer">
                              <span className="ff-prompt-view-text">
                                View Prompt
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <line x1="7" y1="17" x2="17" y2="7"></line>
                                <polyline points="7 7 17 7 17 17"></polyline>
                              </svg>
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </>
              ) : (
                <div className="ff-resources-placeholder">
                  <p>
                    No prompts available at this time. Please check back soon!
                  </p>
                  <button
                    onClick={fetchPrompts}
                    className="ff-btn ff-btn-secondary ff-btn-sm"
                  >
                    Refresh
                  </button>
                </div>
              )}
            </div>

            {/* Newsletter Tab */}
            <div
              className={`ff-tab-content ${
                activeTab === "newsletter" ? "active" : ""
              }`}
              id="newsletter-tab"
            >
              <h2 className="ff-section-title">AI School Leadership Minute</h2>
              <div className="ff-newsletter-container">
                <div className="ff-newsletter-content">
                  <div className="ff-newsletter-info">
                    <h3>Free Weekly Insights for School Leaders</h3>
                    <p>
                      Join hundreds of school administrators receiving our
                      weekly newsletter with:
                    </p>
                    <ul className="ff-newsletter-features">
                      <li>Quick AI tips you can implement immediately</li>
                      <li>Latest developments in AI for education</li>
                      <li>Case studies from schools using AI effectively</li>
                      <li>Answers to common questions from school leaders</li>
                    </ul>
                  </div>

                  <div className="ff-newsletter-cta">
                    <h3>Get weekly updates on AI in education</h3>
                    <p>
                      Join hundreds of school leaders receiving our weekly
                      newsletter with practical AI tips.
                    </p>
                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-btn ff-btn-primary"
                    >
                      Subscribe Now
                    </a>
                  </div>
                </div>

                <div className="ff-newsletter-preview">
                  <h3>Recent Editions</h3>
                  <div className="ff-newsletter-samples">
                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/p/ai-school-leadership-minute-turn-staff-observations-into-an-improvement-plan"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-newsletter-sample"
                    >
                      <div className="ff-newsletter-sample-date">
                        Latest Edition
                      </div>
                      <div className="ff-newsletter-sample-title">
                        Turn Staff Observations into an Improvement Plan
                      </div>
                    </a>

                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/p/ai-school-leadership-minute-fix-chatgpt-table-formatting"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-newsletter-sample"
                    >
                      <div className="ff-newsletter-sample-date">
                        Recent Edition
                      </div>
                      <div className="ff-newsletter-sample-title">
                        Fix ChatGPT Table Formatting in Docs
                      </div>
                    </a>

                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/p/ai-school-leadership-minute-unpack-standards-in-seconds"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-newsletter-sample"
                    >
                      <div className="ff-newsletter-sample-date">
                        Recent Edition
                      </div>
                      <div className="ff-newsletter-sample-title">
                        Unpack Standards in Seconds
                      </div>
                    </a>

                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/p/ai-school-leadership-minute-weekend-videos-to-improve-your-ai-skills"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-newsletter-sample"
                    >
                      <div className="ff-newsletter-sample-date">
                        Recent Edition
                      </div>
                      <div className="ff-newsletter-sample-title">
                        Weekend Videos to Improve Your AI Skills
                      </div>
                    </a>

                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/p/ai-school-leadership-minute-your-brutally-honest-ai-advisor"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-newsletter-sample"
                    >
                      <div className="ff-newsletter-sample-date">
                        Recent Edition
                      </div>
                      <div className="ff-newsletter-sample-title">
                        Your Brutally Honest AI Advisor
                      </div>
                    </a>

                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/p/ai-school-leadership-minute-data-driven-policy-updates"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-newsletter-sample"
                    >
                      <div className="ff-newsletter-sample-date">
                        Recent Edition
                      </div>
                      <div className="ff-newsletter-sample-title">
                        Data-Driven Policy Updates
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FFFooter />
    </div>
  );
};

export default FFResources;
