import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './AASAFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavMoASSPOct28 from './NavAASA';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
import Retention from '../../images/Retention.png'
import Slide from '../../images/Slide.png'
import SH_orange from '../../images/SH_orange.png'
import SpaceFox from '../../images/SpaceFox.png'
import DeptOfEDU from '../../images/DeptOfEDU.png'
import CoSN from '../../images/CoSN.jpg'
import Garble from '../../images/Garble.png'
import AIforEDU from '../../images/aiEDUproj.jpg'

const AASAHumanResources = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top AASA-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavMoASSPOct28 module="resources" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header AASA-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Human Resources Track Resources</div>
                        </div>
                    </header>
                    <div className="course-container">
                        {/* <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="resources"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Resources">📦💡</span> Resources
                                </div>
                            </ScrollLink>
                            <Link
                                target="_blank"
                                to="/training/MoASSP%20&%20MAESP"
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">🎓🌐</span> Training Portal Direct Link
                                </div>
                            </Link>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>

                        </div> */}
                        <section name='retention-tools' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title AASA-course-sub-title aiSupperSummitColor">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                                {/* Retention Resource */}
                                <a
                                    href="https://drive.google.com/file/d/15xGbFIpv-o0YOZu12WubRjxJboJlSrCC/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Slide} alt="Beassie AASA Turnover Retention Tools" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Beassie AASA Turnover Retention Tools
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        PowerPoint presentation for the HR track session focuses on using AI to predict turnover and plan for retention.
                                    </p>
                                </a>
                                <a
                                    href="https://docs.google.com/document/d/1JBZT0TH6SeDaPnwnOWQLjlGM3hicdItb/edit?usp=sharing&ouid=111837834560377585162&rtpof=true&sd=true"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Doc} alt="Beassie AASA Turnover Retention Tools" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Resources for Turnover/Retention Tool Session with Dr. Rhonda Beassie
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                    A resource guide for Dr. Rhonda Beassie’s session, featuring AI prompts, datasets, and analytics tools for improving K-12 employee retention.
                                    </p>
                                </a>
                                <a
                                    href="https://drive.google.com/file/d/1crPfaUwWYo8cih9RXJ3FUKCEWsy299ka/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Slide} alt="AI for Professional Learning" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI for Professional Learning
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        PowerPoint presentation for the HR track session focused on AI for professional learning.
                                    </p>
                                </a>

                                <a
                                    href="https://drive.google.com/file/d/1b9LfaaFL5nP4tpqaXiK0QgpqZHXHOVhz/view?usp=sharing"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Slide} alt="Crowdsourcing AI for Professional Learning and Leadership" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Crowdsourcing AI for Professional Learning and Leadership
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        PowerPoint presentation for the HR track session for Crowdsourcing AI for professional learning and leadership.
                                    </p>
                                </a>

                                <a
                                    href="https://tech.ed.gov/education-leaders-ai-toolkit/"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={DeptOfEDU} alt="Empowering Education Leaders: A Toolkit for Safe, Ethical and Equitable AI Integration" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        Empowering Education Leaders: A Toolkit for Safe, Ethical and Equitable AI Integration
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        This U.S. Department of Education's Office of Educational Technology toolkit provides guidance to help K-12 leaders integrate artificial intelligence in their schools and districts.
                                    </p>
                                </a>

                                <a
                                    href="https://www.aiedu.org/ai-readiness-framework"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIforEDU} alt="AI Readiness Framework: What Students, Educators and District Leaders need to know" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        AI Readiness Framework: What Students, Educators and District Leaders need to know
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        This AI Readiness Framework from The AI Education Project outlines what students, educators, and district leaders need to know about AI.
                                    </p>
                                </a>

                                <a
                                    href="https://www.cosn.org/ai/"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={CoSN} alt="K-12 Gen AI Maturity Tool" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        K-12 Gen AI Maturity Tool
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        This resource provides information to school districts to assess their readiness for the responsible integration of AI and was created by CoSN and the Council of Great City Schools (CGCS) in collaboration with Amazon Web Services (AWS).
                                    </p>
                                </a>

                                <a
                                    href="https://grable.org/publications/the-pittsburgh-principles/"
                                    target="_blank"
                                    className="MoASSP-tool-card AASA-tool-card"
                                >
                                    <div className="MoASSP-tool-icon">
                                        <img src={Garble} alt="The Pittsburgh Principles: Lessons Learned from 15 Years of Stewarding a Learning Ecosystem" />
                                    </div>
                                    <h3 className="MoASSP-tool-title AASA-tool-title">
                                        The Pittsburgh Principles: Lessons Learned from 15 Years of Stewarding a Learning Ecosystem
                                    </h3>
                                    <p className="MoASSP-tool-text AASA-tool-text">
                                        This resource outlines The Pittsburgh Principles that guide the Pittsburgh learning ecosystem.
                                    </p>
                                </a>

                            </div>
                        </section>
                        <section id="aiSuperSUmmitPartners">
                            <div className="aiSuperSUmmitWrapper">
                                <h1 className="heading">
                                    Resources provided by:
                                </h1>
                                <div className="aisupersummitcards">
                                    {[
                                        SH_orange,
                                        SpaceFox
                                    ].map((item, index) => (
                                        <img key={index} src={item} alt="AI Super Summit Partners" />
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default AASAHumanResources;
