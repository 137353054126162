import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FFNavbar from "./FFNavbar";
import FFFooter from "./FFFooter";

const FFTrainingInfo = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [showFloatingCTA, setShowFloatingCTA] = useState(false);

  // Show floating CTA after scrolling down a bit
  useEffect(() => {
    const handleScroll = () => {
      // Show CTA after scrolling down 500px
      if (window.scrollY > -1) {
        setShowFloatingCTA(true);
      } else {
        setShowFloatingCTA(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      // Smooth scroll to contact section
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToCTA = (e) => {
    e.preventDefault();
    const ctaSection = document.querySelector(".ff-cta-section");
    if (ctaSection) {
      // Get the navbar height to offset the scroll position
      const navbar = document.querySelector("ffNav"); // Adjust this selector if your navbar has a different class/tag
      const navbarHeight = navbar ? navbar.offsetHeight : 205;

      // Get the position of the CTA section relative to the top of the page
      const ctaSectionPosition =
        ctaSection.getBoundingClientRect().top + window.pageYOffset;

      // Scroll to the CTA section with an offset for the navbar
      window.scrollTo({
        top: ctaSectionPosition - navbarHeight - 20, // 20px extra padding for visual comfort
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="ff-home ff-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          PD That Teachers Actually Use | Cut Burnout & Boost Confidence with AI
        </title>
        <meta
          name="description"
          content="90% teacher satisfaction guarantee or you don't pay. Practical tools teachers implement immediately, ongoing support, and measurable time savings for your entire staff."
        />
        <meta
          name="keywords"
          content="teacher burnout solutions, professional development that works, save teacher time, practical AI for schools, PD with guaranteed results"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="PD That Teachers Actually Use | Cut Burnout & Boost Confidence with AI"
        />
        <meta
          property="og:description"
          content="90% teacher satisfaction guarantee or you don't pay. Practical tools teachers implement immediately with measurable results."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gruvyeducation.com/training" />
      </Helmet>

      <FFNavbar />

      {/* Floating CTA Button */}
      <div
        className={`ff-floating-cta ${
          showFloatingCTA ? "ff-floating-cta-visible" : ""
        }`}
      >
        <button
          onClick={scrollToCTA}
          className="ff-btn ff-btn-primary ff-floating-btn"
        >
          {" "}
          Schedule A 15-Min Call{" "}
        </button>
      </div>

      {/* Hero Section */}
      <section className="ff-page-header ff-training-header">
        <div className="ff-container">
          <h1 className="ff-page-title">AI Training Teachers Actually Use</h1>
          <p className="ff-page-subtitle">
            If less than 90% of your staff would recommend the training to other
            educators, you don’t pay.
          </p>
        </div>
      </section>

      {/* Training Overview */}
      <section className="ff-section ff-training-overview">
        <div className="ff-container">
          <div className="ff-overview-content">
            <h2 className="ff-section-title">
              Designed To Deliver Real Results
            </h2>
            <div className="ff-overview-grid">
              <div className="ff-overview-card">
                <div className="ff-overview-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                  </svg>
                </div>
                <h3>Efficient Training</h3>
                <p>3-4 hours that respect your staff's time</p>
                <ul className="ff-feature-list">
                  <li>Flexible in-person or virtual formats</li>
                  <li>Hands-on practice, not theory</li>
                </ul>
              </div>

              <div className="ff-overview-card">
                <div className="ff-overview-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                  </svg>
                </div>
                <h3>Ongoing Resources</h3>
                <p>1-Year Portal Access</p>
                <ul className="ff-feature-list">
                  <li>50+ ready-to-use tutorials (5-min each)</li>
                  <li>Updated as AI tools evolve</li>
                </ul>
              </div>

              <div className="ff-overview-card">
                <div className="ff-overview-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <h3>Professional Credit</h3>
                <p>Credits Your Staff Can Actually Use</p>
                <ul className="ff-feature-list">
                  <li>10 continuing education hours</li>
                  <li>University-accredited badges through NDSU</li>
                  <li>Option to enroll for credit through NDSU</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Training Modules Section */}
      <section className="ff-section ff-training-modules">
        <div className="ff-container">
          <h2 className="ff-section-title">
            The 4 Training Modules: Practical Tools, Not Just Theory
          </h2>

          <div className="ff-module-card">
            <div className="ff-module-header">
              <h3>Module 1: From AI Skepticism to Practical Application</h3>
            </div>
            <div className="ff-module-content">
              <p className="ff-module-objective">
                <strong>Outcome:</strong> Staff leave with clear understanding
                of how AI can save them time daily.
              </p>
              <div className="ff-module-details">
                <div className="ff-module-takeaways">
                  <h4>Staff Will Get:</h4>
                  <ul>
                    <li>Immediate hands-on experience with AI tools</li>
                    <li>Confidence to experiment without fear</li>
                    <li>
                      Practical strategies to maximize benefits while avoiding
                      pitfalls
                    </li>
                    <li>Clear perspective on AI's role in today's classroom</li>
                  </ul>
                </div>
                <div className="ff-module-quote">
                  <blockquote>
                    "I was solidly 'anti-AI' before this training. I am now
                    fully on the bandwagon and am looking for more and more ways
                    to use AI to improve my life and the lives of my students."
                    <cite>— Teacher from Swannville Public School</cite>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="ff-module-card">
            <div className="ff-module-header">
              <h3>
                Module 2: Effective ChatGPT Use & Student-Proof Assignments
              </h3>
            </div>
            <div className="ff-module-content">
              <p className="ff-module-objective">
                <strong>Outcome:</strong> Teachers master ChatGPT for prep time
                while creating assignments students can't complete with AI.
              </p>
              <div className="ff-module-details">
                <div className="ff-module-takeaways">
                  <h4>Staff Will Get:</h4>
                  <ul>
                    <li>
                      Concrete methods to use ChatGPT effectively beyond basic
                      prompts
                    </li>
                    <li>
                      Assessment strategies that prevent AI-generated student
                      work
                    </li>
                    <li>
                      Ready-to-implement frameworks for assignments that require
                      human thinking
                    </li>
                  </ul>
                </div>
                <div className="ff-module-quote">
                  <blockquote>
                    "I thought AI wouldn't apply to me as an early elementary
                    teacher. I was wrong! I can't wait to use AI tools to
                    enhance my teaching and student experiences."
                    <cite>— Educator from Duel School District</cite>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          <div className="ff-module-card">
            <div className="ff-module-header">
              <h3>Module 3: AI in the Classroom with Magic School</h3>
            </div>
            <div className="ff-module-content">
              <p className="ff-module-objective">
                <strong>Outcome:</strong> Increase student engagement by 30.06%
                with effective AI classroom tools.
              </p>
              <div className="ff-module-details">
                <div className="ff-module-takeaways">
                  <h4>Staff Will Get:</h4>
                  <ul>
                    <li>Hands-on Magic School training</li>
                    <li>
                      Practical Magic Student setup guide with classroom
                      management strategies
                    </li>
                    <li>
                      Ready-made activities that increase participation and
                      critical thinking
                    </li>
                    <li>
                      Clear framework for teaching students responsible AI
                      literacy
                    </li>
                  </ul>
                </div>
                <div className="ff-module-stat">
                  <div className="ff-stat">
                    <span className="ff-stat-value">30.06%</span>
                    <span className="ff-stat-label">
                      Increase in student engagement when using AI in lessons
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ff-module-card">
            <div className="ff-module-header">
              <h3>Module 4: Time-Saving Workflows for Teachers</h3>
            </div>
            <div className="ff-module-content">
              <p className="ff-module-objective">
                <strong>Outcome:</strong> Save each teacher 3.23 hours weekly
                (100+ hours annually) on routine tasks.
              </p>
              <div className="ff-module-details">
                <div className="ff-module-takeaways">
                  <h4>Staff Will Get:</h4>
                  <ul>
                    <li>
                      Complete workflows for cutting busy work time in half
                    </li>
                    <li>
                      Tools to create differentiated materials for diverse
                      learners (25.87% confidence boost)
                    </li>
                  </ul>
                </div>
                <div className="ff-module-quote">
                  <blockquote>
                    "This is the best and most valuable PD course I have taken
                    since I began teaching."
                    <cite>— Educator from Kidder County School</cite>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          {/* Adding Guarantee Box */}
          <div
            className="ff-guarantee-box"
            style={{
              marginTop: "40px",
              padding: "30px",
              border: "2px solid #005d41", // Changed to match your brand green
              borderRadius: "12px",
              backgroundColor: "rgba(0, 93, 65, 0.05)", // Subtle green background
              position: "relative",
              textAlign: "center",
              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.08)",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-15px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#005d41",
                color: "white",
                padding: "5px 15px",
                borderRadius: "20px",
                fontWeight: "600",
                fontSize: "0.9rem",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              100% RISK-FREE
            </div>
            <h3
              style={{
                marginTop: "10px",
                fontSize: "1.8rem",
                color: "#242424",
                marginBottom: "15px",
              }}
            >
              Our Results Guarantee
            </h3>
            <p
              style={{
                fontSize: "1.3rem",
                color: "#333",
                marginBottom: "20px",
              }}
            >
              If less than 90% of your staff would recommend the training to
              other educators, you don’t pay.
            </p>
            <a
              href="https://usemotion.com/meet/william-grube/meeting?d=30"
              target="_blank"
              rel="noopener noreferrer"
              className="ff-btn ff-btn-primary"
              style={{
                padding: "12px 25px",
                backgroundColor: "#005d41",
                color: "white",
                textDecoration: "none",
                borderRadius: "6px",
                fontWeight: "600",
                fontSize: "1.1rem",
                boxShadow: "0 4px 10px rgba(0, 93, 65, 0.2)",
                display: "inline-block",
                transition: "all 0.3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#004732";
                e.currentTarget.style.boxShadow =
                  "0 6px 15px rgba(0, 93, 65, 0.3)";
                e.currentTarget.style.transform = "translateY(-2px)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#005d41";
                e.currentTarget.style.boxShadow =
                  "0 4px 10px rgba(0, 93, 65, 0.2)";
                e.currentTarget.style.transform = "translateY(0)";
              }}
            >
              Schedule A 15-Min Call
            </a>
          </div>
        </div>
      </section>

      {/* What's Included Section */}
      <section className="ff-section ff-whats-included">
        <div className="ff-container">
          <h2 className="ff-section-title">
            What's Included - Beyond The Initial Training
          </h2>

          <div className="ff-included-grid">
            <div className="ff-included-card">
              <div className="ff-included-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                  <line x1="8" y1="21" x2="16" y2="21"></line>
                  <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
              </div>
              <h3>1-Year Portal Access</h3>
              <ul className="ff-included-list">
                <li>Session recordings for staff who miss training</li>
                <li>Step-by-step tutorials for immediate implementation</li>
                <li>
                  5-minute video activities with measurable time-saving results
                </li>
                <li>Copy-paste prompts and templates for immediate use</li>
              </ul>
              <div className="ff-included-quote">
                <blockquote>
                  "Having access to the Gruvy Education portal is PRICELESS!"
                  <cite>— Educator from North Cedar Academy</cite>
                </blockquote>
              </div>
            </div>

            <div className="ff-included-card">
              <div className="ff-included-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"></path>
                </svg>
              </div>
              <h3>Ongoing Administrative Support</h3>
              <ul className="ff-included-list">
                <li>Q&A sessions to address implementation challenges</li>
                <li>Updates on new AI tools relevant to education</li>
                <li>Usage reports to track staff engagement</li>
                <li>Policy templates for responsible AI use</li>
              </ul>
            </div>

            <div className="ff-included-card">
              <div className="ff-included-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <h3>Role-Specific Courses</h3>
              <ul className="ff-included-list">
                <li>Administration and School Leadership</li>
                <li>Special Education</li>
                <li>Music Teachers</li>
                <li>Physical Education</li>
                <li>ESL Teachers</li>
                <li>School Counselors</li>
                <li>Sports Coaches</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Delivery Options */}
      <section className="ff-section ff-delivery-options">
        <div className="ff-container">
          <h2 className="ff-section-title">Flexible Training Options</h2>

          <div className="ff-delivery-cards">
            <div className="ff-delivery-card">
              <div className="ff-delivery-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M3 12h18M3 6h18M3 18h18"></path>
                </svg>
              </div>
              <h3>In-Person Training</h3>
              <p>Efficient, hands-on PD day for your entire staff</p>
            </div>

            <div className="ff-delivery-card">
              <div className="ff-delivery-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15.6 11.6L22 7v10l-6.4-4.5v-1zM4 5h9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2z" />
                </svg>
              </div>
              <h3>Virtual Sessions</h3>
              <p>Same results-focused content, delivered online</p>
            </div>

            <div className="ff-delivery-card">
              <div className="ff-delivery-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                  <line x1="8" y1="21" x2="16" y2="21"></line>
                  <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
              </div>
              <h3>Portal-Only Access</h3>
              <p>Self-directed implementation for budget-conscious schools</p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Highlight */}
      <section className="ff-section ff-training-testimonials">
        <div className="ff-container">
          <h2 className="ff-section-title">What Other School Leaders Say</h2>

          <div className="ff-testimonial-highlights">
            <div className="ff-testimonial-highlight">
              <p>
                "William is by far one of the best trainers we've had during my
                20 years in education. Very knowledgeable, presented well, and
                efficient. I still hear staff talking about how they are using
                it regularly within their teaching day."
              </p>
              <div className="ff-testimonial-source">
                <span className="ff-testimonial-role">
                  Dan Spellerberg, Director
                </span>
                <span className="ff-testimonial-school">
                  Southeast Region Career and Technology Center
                </span>
              </div>
            </div>

            <div className="ff-testimonial-highlight">
              <p>
                "This training empowered our staff to embrace tools that
                streamline routine tasks, allowing them to focus more on
                teaching and addressing student needs. I've seen real changes in
                how efficiently my teachers work."
              </p>
              <div className="ff-testimonial-source">
                <span className="ff-testimonial-role">Superintendent</span>
                <span className="ff-testimonial-school">
                  Alexander Public Schools
                </span>
              </div>
            </div>
          </div>

          <div className="ff-testimonial-cta">
            <Link to="/testimonials" className="ff-btn ff-btn-secondary">
              Read More Testimonials
            </Link>
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="ff-section ff-cta-section" id="contact">
        <div className="ff-container">
          <h2 className="ff-section-title">Next Steps: No Lengthy Process</h2>
          <p className="ff-section-description">
            Book a 15-minute call to discuss your school's needs and pricing. If
            less than 90% of your staff would recommend the training to other
            educators, you don’t pay.
          </p>
          <div className="ff-cta-buttons">
            <a
              href="https://usemotion.com/meet/william-grube/meeting?d=30"
              target="_blank"
              rel="noopener noreferrer"
              className="ff-btn ff-btn-primary"
            >
              Schedule a 15-Min Call
            </a>
            <span className="ff-cta-separator">or</span>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                const contactFormSection =
                  document.querySelector(".ff-footer-contact");
                if (contactFormSection) {
                  contactFormSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="ff-btn ff-btn-secondary"
            >
              Contact us for Information
            </a>
          </div>
        </div>
      </section>

      <FFFooter />
    </div>
  );
};

export default FFTrainingInfo;
