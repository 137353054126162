import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import db from "../../Firebase";

const FFTestimonialCarousel = ({ 
  maxVisible = 2, 
  maxTotal = 10, 
  showMoreLink = true,
  featuredOnly = false,
  title = "What Educators Are Saying" 
}) => {
  // State for testimonials
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(null);

  // Fetch testimonials from Firebase
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        // Get all testimonials from the collection
        const collectionRef = collection(db, "leaderTestimonials");
        
        // Query with orderBy only to avoid index requirement
        const basicQuery = query(
          collectionRef,
          orderBy("rank", "asc"),
          limit(100)
        );
        
        const snapshot = await getDocs(basicQuery);
        
        // Map and filter the data
        let testimonialsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Apply featured filter if needed
        if (featuredOnly) {
          testimonialsData = testimonialsData.filter(item => item.featured === true);
        }
        
        // Take only the number we need
        testimonialsData = testimonialsData.slice(0, maxTotal);
        
        setTestimonials(testimonialsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    
    fetchTestimonials();
  }, [maxTotal, featuredOnly]);

  // Navigate to previous testimonials
  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - maxVisible);
    } else {
      // Wrap around to the end if at the beginning
      const lastPageIndex = Math.floor((testimonials.length - 1) / maxVisible) * maxVisible;
      setCurrentIndex(lastPageIndex);
    }
  };

  // Navigate to next testimonials
  const goToNext = () => {
    if (currentIndex + maxVisible < testimonials.length) {
      setCurrentIndex(currentIndex + maxVisible);
    } else {
      // Wrap around to the beginning if at the end
      setCurrentIndex(0);
    }
  };

  // Handle loading state
  if (loading) {
    return <div className="ff-testimonial-loading">Loading testimonials...</div>;
  }

  // Check if there are any testimonials
  if (testimonials.length === 0) {
    return null; // Don't show anything if no testimonials
  }

  // Calculate if navigation arrows should be shown
  const showNavigation = testimonials.length > maxVisible;

  return (
    <div className="ff-testimonial-carousel ff-font">
      {title && <h2 className="ff-section-title">{title}</h2>}
      
      <div className="ff-carousel-container">
        {showNavigation && (
          <button 
            className="ff-carousel-arrow ff-carousel-prev" 
            onClick={goToPrevious}
            aria-label="Previous testimonials"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="currentColor" />
            </svg>
          </button>
        )}
        
        <div className="ff-testimonial-cards">
          {testimonials.slice(currentIndex, currentIndex + maxVisible).map(testimonial => (
            <div 
              key={testimonial.id} 
              className={`ff-testimonial-card ${testimonial.featured ? 'ff-testimonial-featured' : ''}`}
            >
              <p className="ff-testimonial-text">"{testimonial.testimonial}"</p>
              <p className="ff-testimonial-author">
                - {testimonial.name}, {testimonial.position} at {testimonial.schoolName}
              </p>
            </div>
          ))}
        </div>
        
        {showNavigation && (
          <button 
            className="ff-carousel-arrow ff-carousel-next" 
            onClick={goToNext}
            aria-label="Next testimonials"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="currentColor" />
            </svg>
          </button>
        )}
      </div>
      
      {/* Pagination indicators */}
      {showNavigation && (
        <div className="ff-carousel-pagination">
          {Array.from({ length: Math.ceil(testimonials.length / maxVisible) }).map((_, index) => (
            <button
              key={index}
              className={`ff-carousel-dot ${currentIndex === index * maxVisible ? 'ff-carousel-dot-active' : ''}`}
              onClick={() => setCurrentIndex(index * maxVisible)}
              aria-label={`Go to page ${index + 1}`}
            />
          ))}
        </div>
      )}
      
      {showMoreLink && (
        <div className="ff-testimonial-more">
          <Link to="/testimonials" className="ff-btn ff-btn-secondary">
            See All Success Stories
          </Link>
        </div>
      )}
    </div>
  );
};

export default FFTestimonialCarousel;