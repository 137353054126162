import "./App.css";
import "./Components/A1FrontFacing/FF.css";
import FFHome from "./Components/A1FrontFacing/home";
import FFAbout from "./Components/A1FrontFacing/FFAbout";
import FFTestimonials from "./Components/A1FrontFacing/FFTestimonials";
import FFTrainingPortal from "./Components/A1FrontFacing/FFTrainingPortal";
import FFTrainingInfo from "./Components/A1FrontFacing/FFTrainingInfo";
import FFResources from "./Components/A1FrontFacing/FFResources";
import FFPromptLibrary from "./Components/A1FrontFacing/FFPromptLibrary";
import React from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import TrainingAuth from "./Components/TrainingAuth";
import Training from "./Components/TrainingHome";
import Testimonials from "./Components/Testimonials";
import Fergus1 from "./Components/Fergus1";
import Fergus2 from "./Components/Fergus2";
import Fergus3 from "./Components/Fergus3";
import ProtectedRoute from "./Components/ProtectedRoute";
import db from "./Firebase";
import { useState, useEffect } from "react";
import { collection, doc, getDocs } from "firebase/firestore";
import { AuthContextProvider } from "./Context/authContext";
import AItools from "./Components/AItools";
import PromptLibrary from "./Components/PromptLibrary";
import SalesPage from "./Components/SalesPage";
import PresentationSales from "./Components/PresentationSales";
import DayOneCourse from "./Components/DayOneCourse";
import DayFiveCourse from "./Components/DayFiveCourse";
import ViewPollResults from "./Components/ViewPollResults";
import DayTwoCourse from "./Components/DayTwoCourse";
import AdminProgressReport from "./Components/AdminProgessReport";
import ProgressReport from "./Components/ProgressReport";
import DayThreeCourse from "./Components/DayThreeCourse";
import DayFourCourse from "./Components/DayFourCourse";
import SuperSecretAdmin from "./Components/SuperSecretAdmin";
import ResetPassword from "./Components/ResetPassword";
import AdminCourse from "./Components/AdminCourse";
import NDASApresentation from "./Components/NDASApresentation";
import SCSC from "./Components/SCSC";
import SCSCHumanCapitol from "./Components/SCSCHumanCapitol";
import SCSCMiscellaneousNeeds from "./Components/SCSCMiscellaneousNeeds";
import SCSCCommunication from "./Components/SCSCCommunication";
import SCSCTeachingAndLearning from "./Components/SCSCTeachingAndLearning";
import NDASAhumanCapitol from "./Components/NDASAhumanCapitol";
import NDASAbusinessServices from "./Components/NDASAbusinessServices";
import NDASAcommunication from "./Components/NDASAcommunication";
import NDASAteachingAndLearning from "./Components/NDASAteachingAndLearning";
import NDCELprincipals from "./Components/NDCELprincipals";
import NDCELprincipalsHumanCapitol from "./Components/NDCELprincipalsHumanCapitol";
import NDCELprincipalsMiscellaneousNeeds from "./Components/NDCELprincipalsMiscellaneousNeeds";
import NDCELprincipalsCommunication from "./Components/NDCELprincipalsCommunication";
import NDCELprincipalsTeachingAndLearning from "./Components/NDCELprincipalsTeachingAndLearning";
import DPI from "./Components/DPI";
import DPIHumanResources from "./Components/DPIHumanResources";
import DPITeachingAndLearning from "./Components/DPITeachingAndLearning";
import DPIbusinessServices from "./Components/DPIbusinessServices";
import Canjo from "./Components/Canjo";
import CanjoHumanCapitol from "./Components/CanjoHumanCapitol";
import CanjoMiscellaneousNeeds from "./Components/CanjoMiscellaneousNeeds";
import CanjoCommunication from "./Components/CanjoCommunication";
import CanjoTeachingAndLearning from "./Components/CanjoTeachingAndLearning";
import CanjoBusinessServices from "./Components/CanjoBusinessServices";
import VAportal from "./Components/VAportal";
import Sourcewell from "./Components/Sourcewell";
import SourcewellBreakout1 from "./Components/SourcewellBreakout1";
import SourcewellBreakout2 from "./Components/SourcewellBreakout2";
import SourcewellBreakout3 from "./Components/SourcewellBreakout3";
import OfficeStaff from "./Components/OfficeStaff";
import OfficeStaffCommunication from "./Components/OfficeStaffCommunication";
import OfficeStaffContentCreation from "./Components/OfficeStaffContentCreation";
import OfficeStaffData from "./Components/OfficeStaffData";
import AIResourceCenterforSchoolLeaders from "./Components/LeadMagnet/AIResourceCenterforSchoolLeaders";
import MoASSP from "./Components/MoASSP/MoASSP";
import AboutGruvyEducation from "./Components/MoASSP/AboutGruvyEducation";
import AboutGruvyEducationBreakout from "./Components/MoASSP/AboutGruvyEducationBreakout";
import MoASSPBreakout from "./Components/MoASSP/MoASSPBreakout";
import GoogleApplication from "./Components/GoogleApplication";
import NDCELBreakout1 from "./Components/NDCEL/NDCELBreakout1";
import NDCEL1AboutGruvyEducationBreakout from "./Components/NDCEL/NDCEL1AboutGruvyEducationBreakout";
import NDCELBreakout2 from "./Components/NDCEL/NDCELBreakout2";
import NDCEL2AboutGruvyEducationBreakout from "./Components/NDCEL/NDCEL2AboutGruvyEducationBreakout";
import SchoolBoardBreakout from "./Components/NDSchoolBoard/SchoolBoardBreakout";
import SchoolBoardAboutGruvyEducationBreakout from "./Components/NDSchoolBoard/SchoolBoardAboutGruvyEducationBreakout";
import MoASSPOct28Breakout from "./Components/MoASSPOct28/MoASSPOct28Breakout";
import MoASSPOct28AboutGruvyEducationBreakout from "./Components/MoASSPOct28/MoASSPOct28AboutGruvyEducationBreakout";
import MoASSPOct28Resources from "./Components/MoASSPOct28/MoASSPOct28Resources";
import AASABusinessAndOperations from "./Components/AASA/AASABusinessAndOperations";
import AASACommunicationMarketingAndSafety from "./Components/AASA/AASACommunicationMarketingAndSafety";
import AASAHumanResources from "./Components/AASA/AASAHumanResources";
import AASATeachingAndLearning from "./Components/AASA/AASATeachingAndLearning";
import CanjoSession1 from "./Components/Canjo2024/CanjoSession1";
import CanjoResources from "./Components/Canjo2024/CanjoResources";
import CISBSchoolBoardBreakout from "./Components/CISB/SchoolBoardBreakout";
import CISBSchoolBoardAboutGruvyEducationBreakout from "./Components/CISB/SchoolBoardAboutGruvyEducationBreakout";
import CanjoSession2 from "./Components/Canjo2024/CanjoSession2";
import CanjoResources2 from "./Components/Canjo2024/CanjoResources2";
import AllResources from "./Components/All Resources/AllResources";
import AllResourcesAboutGruvyEducationBreakout from "./Components/All Resources/AllResourcesAboutGruvyEducationBreakout";
import Webinar1 from "./Components/Webinar1/Webinar1";
import Webinar1Resources from "./Components/Webinar1/Webinar1Resources";
import Webinar2 from "./Components/Webinar2/Webinar2";
import Webinar2Resources from "./Components/Webinar2/Webinar2Resources";
import AASAHome from "./Components/AASA/AASAHome";
import CanjoSession3 from "./Components/Canjo2024/CanjoSession3";
import CanjoResources3 from "./Components/Canjo2024/CanjoResources3";
import BerthaSession from "./Components/Canjo2024/Bertha/BerthaSession";
import BerthaResources from "./Components/Canjo2024/Bertha/BerthaResources";
import Webinar3 from "./Components/Webinar3/Webinar3";
import Webinar3Resources from "./Components/Webinar3/Webinar3Resources";
import Jan20 from "./Components/Jan20/Jan20";
import Jan22Resources from "./Components/Jan22/Jan22Resources";
import Jan22 from "./Components/Jan22/Jan22";
import AASAHackathon from "./Components/AASA/AASAHackathon";
import AASAPolicyAndGovernace from "./Components/AASA/AASAPolicyAndGovernace";
import AASAAISparkSessions from "./Components/AASA/AASAAISparkSessions";
import AASASharkSessionShowcase from "./Components/AASA/AASASharkSessionShowcase";
import AASAPartnerPresentations from "./Components/AASA/AASAPartnerPresentations";
import AASAAllResources from "./Components/AASA/AASAAllResources";
import MESPA from "./Components/Mespa/MESPA";
import MESPAResources from "./Components/Mespa/MESPAResources";
import ThirtyPlus from "./Components/BeeHiiv/ThirtyPlus";
import DataAnalysisNewsletter from "./Components/BeeHiiv/DataAnalysisNewsletter";
import TenAIPrompts from "./Components/BeeHiiv/TenAIPrompts";
import SchoolLeaderPromptFramework from "./Components/BeeHiiv/SchoolLeaderPromptFramework";
import AnalyzeStudentData from "./Components/BeeHiiv/AnalyzeStudentData";
import EmailGuide from "./Components/BeeHiiv/EmailGuide";
import ToneGuide from "./Components/BeeHiiv/ToneGuide";
import SlideDeck from "./Components/BeeHiiv/SlideDeck";
import AnalyzePDSurvey from "./Components/BeeHiiv/AnalyzePDSurvey";

const App = () => {
  const [schools, setSchools] = useState([]);
  const schoolsRef = collection(db, "School");

  useEffect(() => {
    const getSchools = async () => {
      const data = await getDocs(schoolsRef);
      setSchools(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    };
    getSchools();
  }, []);

  return (
    <AuthContextProvider>
      <BrowserRouter>
        {schools.map((school) => (
          <Link to={`/training/${school.URL}`} />
        ))}
        <Routes>
          <Route path="/" exact element={<FFHome />} />
          <Route path="/training" element={<FFTrainingPortal />} />
          <Route path="/training/:id" element={<Training />} />
          <Route path="/about" element={<FFAbout />} />
          <Route path="/freeresources" element={<FFResources />} />
          <Route
            path="/trainingportalapplication"
            element={<GoogleApplication />}
          />
          <Route path="/testimonials" element={<FFTestimonials />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/training/:schoolID/day1" element={<DayOneCourse />} />
            <Route path="/training/:schoolID/day2" element={<DayTwoCourse />} />
            <Route
              path="/training/:schoolID/day3"
              element={<DayThreeCourse />}
            />
            <Route
              path="/training/:schoolID/day4"
              element={<DayFourCourse />}
            />
            <Route
              path="/training/:schoolID/day5"
              element={<DayFiveCourse />}
            />
            <Route
              path="/training/:schoolID/admin-training"
              element={<AdminCourse />}
            />
            <Route
              path="/supersectretadminpage"
              element={<SuperSecretAdmin />}
            />
          </Route>
          <Route
            path="/view-poll-results/:schoolURL/:dayNumber/:pollNumber/:question/:type"
            element={<ViewPollResults />}
          />
          <Route
            path="/adminProgressReport/:schoolURL"
            element={<AdminProgressReport />}
          />
          <Route
            path="/ProgressReport/:schoolURL"
            element={<ProgressReport />}
          />
          <Route path="/traininginfo" exact element={<FFTrainingInfo />} />
          <Route
            path="/presentationinfo"
            exact
            element={<PresentationSales />}
          />
          <Route path="/promptlibrary" exact element={<FFPromptLibrary />} />
          <Route path="/firebase" exact element={<ResetPassword />} />
          <Route path="/aitools" exact element={<AItools />} />
          <Route path="/VAportal" exact element={<VAportal />} />\
          {/*LEAD MAGENR*/}
          <Route
            path="/AdminResourceCenter"
            exact
            element={<AIResourceCenterforSchoolLeaders />}
          />
          {/*NDASA*/}
          <Route
            path="/ndasa/humancapitol"
            exact
            element={<NDASAhumanCapitol />}
          />
          <Route
            path="/ndasa/businessservices"
            exact
            element={<NDASAbusinessServices />}
          />
          <Route
            path="/ndasa/communication"
            exact
            element={<NDASAcommunication />}
          />
          <Route
            path="/ndasa/teachingandlearning"
            exact
            element={<NDASAteachingAndLearning />}
          />
          <Route path="/ndasa" exact element={<NDASApresentation />} />
          <Route
            path="/ndasa/humancapitol"
            exact
            element={<NDASAhumanCapitol />}
          />
          <Route
            path="/ndasa/businessservices"
            exact
            element={<NDASAbusinessServices />}
          />
          <Route
            path="/ndasa/communication"
            exact
            element={<NDASAcommunication />}
          />
          <Route
            path="/ndasa/teachingandlearning"
            exact
            element={<NDASAteachingAndLearning />}
          />
          {/*Fergus*/}
          <Route path="/fergus1" exact element={<Fergus1 />} />
          <Route path="/fergus2" exact element={<Fergus2 />} />
          <Route path="/fergus3" exact element={<Fergus3 />} />
          {/*BERTHA*/}
          <Route path="/bertha" exact element={<BerthaSession />} />
          <Route path="/bertharesources" exact element={<BerthaResources />} />
          {/*CANJO*/}
          <Route path="/canjo1" exact element={<CanjoSession1 />} />
          <Route path="/canjoresources" exact element={<CanjoResources />} />
          <Route path="/canjo2" exact element={<CanjoSession2 />} />
          <Route path="/canjoresources2" exact element={<CanjoResources2 />} />
          <Route path="/canjo3" exact element={<CanjoSession3 />} />
          <Route path="/canjoresources3" exact element={<CanjoResources3 />} />
          {/*BeeHiiv*/}
          <Route
            path="/ChatGPTGuideforSchoolLeaders"
            exact
            element={<ThirtyPlus />}
          />
          <Route
            path="/slidedeck"
            exact
            element={<SlideDeck />}
          />
          <Route
            path="/analyzepdsurvey"
            exact
            element={<AnalyzePDSurvey />}
          />
          <Route
            path="/DataAnalysisNewsletter"
            exact
            element={<DataAnalysisNewsletter />}
          />
          <Route path="/TenAIPrompts" exact element={<TenAIPrompts />} />
          <Route
            path="/SchoolLeaderPromptFramework"
            exact
            element={<SchoolLeaderPromptFramework />}
          />
          <Route
            path="/AnalyzeStudentData"
            exact
            element={<AnalyzeStudentData />}
          />
          <Route path="/EmailGuide" exact element={<EmailGuide />} />
          <Route path="/ToneGuide" exact element={<ToneGuide />} />
          {/*Webinar1*/}
          <Route path="/webinar1" exact element={<Webinar1 />} />
          <Route
            path="/webinar1resources"
            exact
            element={<Webinar1Resources />}
          />
          {/*Webinar2*/}
          <Route path="/webinar2" exact element={<Webinar2 />} />
          <Route
            path="/webinar2resources"
            exact
            element={<Webinar2Resources />}
          />
          {/*Webinar2*/}
          <Route path="/webinar3" exact element={<Webinar3 />} />
          <Route
            path="/webinar3resources"
            exact
            element={<Webinar3Resources />}
          />
          {/*MESPA Conference*/}
          <Route path="/MESPA" exact element={<MESPA />} />
          <Route path="/MESPAresources" exact element={<MESPAResources />} />
          {/*Special Education Directors*/}
          <Route path="/jan22" exact element={<Jan22 />} />
          <Route path="/jan22resources" exact element={<Jan22Resources />} />
          {/*Keynote*/}
          <Route path="/jan20" exact element={<Jan20 />} />
          {/*AASA*/}
          <Route path="/AASA" exact element={<AASAHome />} />
          <Route
            path="/AASATeachingAndLearning"
            exact
            element={<AASATeachingAndLearning />}
          />
          <Route
            path="/AASAHumanResources"
            exact
            element={<AASAHumanResources />}
          />
          <Route
            path="/AASABusinessAndOperations"
            exact
            element={<AASABusinessAndOperations />}
          />
          <Route
            path="/AASACommunicationsMarketingAndSafety"
            exact
            element={<AASACommunicationMarketingAndSafety />}
          />
          <Route path="/AASAHackathonRoom" exact element={<AASAHackathon />} />
          <Route
            path="/AASAPolicyAndGovernance"
            exact
            element={<AASAPolicyAndGovernace />}
          />
          <Route
            path="/AASAAISparkSessions"
            exact
            element={<AASAAISparkSessions />}
          />
          <Route
            path="/AASASharkShowcaseSession"
            exact
            element={<AASASharkSessionShowcase />}
          />
          <Route
            path="/AASAPartnerPresentations"
            exact
            element={<AASAPartnerPresentations />}
          />
          <Route
            path="/AASAAllResources"
            exact
            element={<AASAAllResources />}
          />
          {/*NDCEL Fall 2024*/}
          <Route path="/ndcel1" exact element={<NDCELBreakout1 />} />
          <Route
            path="/ndcel1aboutgruvyeducationbreakout"
            exact
            element={<NDCEL1AboutGruvyEducationBreakout />}
          />
          <Route path="/ndcel2" exact element={<NDCELBreakout2 />} />
          <Route
            path="/ndcel2aboutgruvyeducationbreakout"
            exact
            element={<NDCEL2AboutGruvyEducationBreakout />}
          />
          {/*NDSBA Fall 2024*/}
          <Route path="/ndsba" exact element={<SchoolBoardBreakout />} />
          <Route
            path="/ndsbaaboutgruvyeducationbreakout"
            exact
            element={<SchoolBoardAboutGruvyEducationBreakout />}
          />
          {/*All Resources*/}
          <Route path="/allresources" exact element={<AllResources />} />
          <Route
            path="/allresourcesaboutgruvyeducationbreakout"
            exact
            element={<AllResourcesAboutGruvyEducationBreakout />}
          />
          {/*Cambridge-Isanti Schools: School Board*/}
          <Route path="/cisb" exact element={<CISBSchoolBoardBreakout />} />
          <Route
            path="/cisbaboutgruvyeducationbreakout"
            exact
            element={<CISBSchoolBoardAboutGruvyEducationBreakout />}
          />
          {/*MoASSP Oct 28*/}
          <Route path="/MoASSPOct28" exact element={<MoASSPOct28Breakout />} />
          <Route
            path="/MoASSPOct28Resources"
            exact
            element={<MoASSPOct28Resources />}
          />
          <Route
            path="/MoASSPOct28aboutgruvyeducationbreakout"
            exact
            element={<MoASSPOct28AboutGruvyEducationBreakout />}
          />
          {/*NDCEL Principals*/}
          <Route path="/ndcelprincipals" exact element={<NDCELprincipals />} />
          <Route
            path="/ndcelprincipals/humancapitol"
            exact
            element={<NDCELprincipalsHumanCapitol />}
          />
          <Route
            path="/ndcelprincipals/miscellaneousneeds"
            exact
            element={<NDCELprincipalsMiscellaneousNeeds />}
          />
          <Route
            path="/ndcelprincipals/communication"
            exact
            element={<NDCELprincipalsCommunication />}
          />
          <Route
            path="/ndcelprincipals/teachingandlearning"
            exact
            element={<NDCELprincipalsTeachingAndLearning />}
          />
          {/*MoASSP*/}
          <Route path="/MoASSP" exact element={<MoASSP />} />
          <Route
            path="/AboutGruvyEducation"
            exact
            element={<AboutGruvyEducation />}
          />
          <Route
            path="/AboutGruvyEducationBreakout"
            exact
            element={<AboutGruvyEducationBreakout />}
          />
          <Route path="/MoASSPBreakout" exact element={<MoASSPBreakout />} />
          {/*Sourcewell*/}
          <Route path="/sourcewell" exact element={<Sourcewell />} />
          <Route
            path="/sourcewell/breakout1"
            exact
            element={<SourcewellBreakout1 />}
          />
          <Route
            path="/sourcewell/breakout2"
            exact
            element={<SourcewellBreakout2 />}
          />
          <Route
            path="/sourcewell/breakout3"
            exact
            element={<SourcewellBreakout3 />}
          />
          {/*Office Staff */}
          <Route path="/officestaff" exact element={<OfficeStaff />} />
          <Route
            path="/officestaff/communication"
            exact
            element={<OfficeStaffCommunication />}
          />
          <Route
            path="/officestaff/contentcreation"
            exact
            element={<OfficeStaffContentCreation />}
          />
          <Route path="/officestaff/data" exact element={<OfficeStaffData />} />
          {/*SCSC*/}
          <Route path="/scsc" exact element={<SCSC />} />
          <Route
            path="/scsc/humancapitol"
            exact
            element={<SCSCHumanCapitol />}
          />
          <Route
            path="/scsc/miscellaneousneeds"
            exact
            element={<SCSCMiscellaneousNeeds />}
          />
          <Route
            path="/scsc/communication"
            exact
            element={<SCSCCommunication />}
          />
          <Route
            path="/scsc/teachingandlearning"
            exact
            element={<SCSCTeachingAndLearning />}
          />
          {/*CANJO*/}
          <Route path="/canjo" exact element={<Canjo />} />
          <Route
            path="/canjo/humancapitol"
            exact
            element={<CanjoHumanCapitol />}
          />
          <Route
            path="/canjo/miscellaneousneeds"
            exact
            element={<CanjoMiscellaneousNeeds />}
          />
          <Route
            path="/canjo/communication"
            exact
            element={<CanjoCommunication />}
          />
          <Route
            path="/canjo/teachingandlearning"
            exact
            element={<CanjoTeachingAndLearning />}
          />
          <Route
            path="/canjo/businessservices"
            exact
            element={<CanjoBusinessServices />}
          />
          {/*DPI*/}
          <Route path="/nddpi" exact element={<DPI />} />
          <Route
            path="/nddpi/humanresources"
            exact
            element={<DPIHumanResources />}
          />
          <Route
            path="/nddpi/businessservices"
            exact
            element={<DPIbusinessServices />}
          />
          <Route
            path="/nddpi/teachingandlearning"
            exact
            element={<DPITeachingAndLearning />}
          />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
};

export default App;
