import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import LogoWhite from "../../images/logo-no-background.png"; // White version of logo
import Logo from "../../images/logo-no-background.png"; // Regular logo as fallback

const FFFooter = () => {
  const currentYear = new Date().getFullYear();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  // Send email using EmailJS
  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitting(true);

    emailjs
      .sendForm(
        "service_1g4hajp",
        "template_fjby2xf",
        e.target,
        "user_HAMTDSUiL200UN9RQyPHI"
      )
      .then(
        (result) => {
          setFormSubmitted(true);
          setSubmitting(false);
          e.target.reset();
        },
        (error) => {
          setFormError(
            "There was an error sending your message. Please try again."
          );
          setSubmitting(false);
        }
      );
  };

  return (
    <footer id="contact" className="ff-footer ff-font">
      {/* Contact Form Section */}
      <div className="ff-footer-contact">
        <div className="ff-container">
          <div className="ff-section-header">
            <h2>Contact Us</h2>
            <p>
              Have questions about our AI training? We'd love to hear from you.
            </p>
          </div>

          <div className="ff-contact-container">
            <div className="ff-contact-info">
              <div className="ff-contact-method">
                <div className="ff-contact-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                </div>
                <div className="ff-contact-details">
                  <h4>Email</h4>
                  <a href="mailto:william.grube@gruvyeducation.com">
                    william.grube@gruvyeducation.com
                  </a>
                </div>
              </div>

              <div className="ff-contact-method">
                <div className="ff-contact-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                  </svg>
                </div>
                <div className="ff-contact-details">
                  <h4>Phone</h4>
                  <a href="tel:507-476-8096">507-476-8096</a>
                </div>
              </div>

              <div className="ff-contact-support">
                <p>
                  Need a quick response? Use our contact form and we'll get back
                  to you as soon as possible.
                </p>
              </div>
            </div>

            <div className="ff-contact-form-wrapper">
              {formSubmitted ? (
                <div className="ff-form-success">
                  <div className="ff-success-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                      <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                  </div>
                  <h3>Message Sent!</h3>
                  <p>
                    Thanks for reaching out. We'll get back to you as soon as
                    possible.
                  </p>
                </div>
              ) : (
                <form onSubmit={sendEmail} className="ff-contact-form">
                  <h3>Send Us a Message</h3>

                  <div className="ff-form-group">
                    <label htmlFor="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your name"
                      required
                    />
                  </div>

                  <div className="ff-form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="your@email.com"
                      required
                    />
                  </div>

                  <div className="ff-form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Your phone number"
                      required
                    />
                  </div>

                  <div className="ff-form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      rows="6"
                      placeholder="Tell us what you need help with..."
                      required
                    ></textarea>
                  </div>

                  {formError && (
                    <div className="ff-form-error">{formError}</div>
                  )}

                  <div className="ff-form-buttons">
                    <button
                      type="submit"
                      className="ff-btn ff-btn-primary ff-btn-submit"
                      disabled={submitting}
                    >
                      {submitting ? "Sending..." : "Send Message"}
                    </button>

                    <a
                      href="https://usemotion.com/meet/william-grube/meeting?d=30"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-btn ff-btn-secondary ff-btn-meeting"
                    >
                      Schedule a Virtual Meeting
                    </a>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Footer */}
      <div className="ff-footer-main">
        <div className="ff-container">
          <div className="ff-footer-content">
            <div className="ff-footer-brand">
              <Link to="/">
                <img
                  src={LogoWhite}
                  alt="Gruvy Education Logo"
                  className="ff-footer-logo"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = Logo;
                  }}
                />
              </Link>
              <p className="ff-footer-tagline">
                Empowering educators with practical AI training
              </p>

              {/* Social media links moved to footer */}
              <div className="ff-social-icons">
                <a
                  href="https://www.youtube.com/@GruvyEducation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="YouTube"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/gruvyeducation/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/Gruvyeducation"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="X (Twitter)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61574229270835"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/95759627"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
                  </svg>
                </a>
              </div>
            </div>

            <div className="ff-footer-links">
              <div className="ff-footer-section">
                <h4 className="ff-footer-title">Training</h4>
                <ul className="ff-footer-menu">
                  <li>
                    <Link to="/traininginfo">Training Info</Link>
                  </li>
                  <li>
                    <Link to="/training">Training Portal</Link>
                  </li>
                </ul>
              </div>

              <div className="ff-footer-section">
                <h4 className="ff-footer-title">Resources</h4>
                <ul className="ff-footer-menu">
                  <li>
                    <Link to="/freeresources">Admin Resources</Link>
                  </li>
                  <li>
                    <a
                      href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Admin Exclusive Newsletter
                    </a>
                  </li>
                  <li>
                    <Link to="/promptlibrary">Prompt Library</Link>
                  </li>
                </ul>
              </div>

              <div className="ff-footer-section">
                <h4 className="ff-footer-title">Connect</h4>
                <ul className="ff-footer-menu">
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                  <li>
                    <Link to="/testimonials">Testimonials</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="ff-footer-bottom">
            <p className="ff-copyright">
              &copy; {currentYear} Gruvy Education. All rights reserved.
            </p>
            <div className="ff-footer-legal">
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/terms">Terms of Service</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FFFooter;
