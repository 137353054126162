import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { collection, getDocs } from 'firebase/firestore';
import db from '../../Firebase';
import Logo from "../../images/logo-no-background.png"; // Import the logo for fallback
import FFNavbar from './FFNavbar';
import FFFooter from './FFFooter';

const FFTrainingPortal = () => {
  useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });
  // State for schools data
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [imageErrors, setImageErrors] = useState({}); // Track which images failed to load
  
  // Navigation
  const navigate = useNavigate();
  
  // Scroll to top on page load
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // Fetch schools from Firebase
  useEffect(() => {
    const getSchools = async () => {
      try {
        setLoading(true);
        const schoolsRef = collection(db, "School");
        const data = await getDocs(schoolsRef);
        const schoolsData = data.docs.map((doc) => ({
          ...doc.data(), 
          id: doc.id
        }));
        
        setSchools(schoolsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching schools:", error);
        setLoading(false);
      }
    };
    
    getSchools();
  }, []);
  
  // Handle image load errors
  const handleImageError = (schoolId) => {
    setImageErrors(prev => ({
      ...prev,
      [schoolId]: true
    }));
  };
  
  // Filter schools based on search term
  const filteredSchools = schools.filter((school) => {
    return school.Name.toLowerCase().includes(searchTerm.toLowerCase());
  }).filter(school => school.trainingType !== "positionSpecific");
  
  // Navigate to school training page
  const navigateToSchoolTraining = (schoolId) => {
    localStorage.setItem("schoolid", schoolId);
    navigate(`/training/${schoolId}`);
  };

  return (
    <div className="ff-page ff-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Training Portal | Gruvy Education</title>
        <meta
          name="description"
          content="Access your school's AI training materials with Gruvy Education's training portal. Find your school to get started."
        />
        <meta
          name="keywords"
          content="AI Education Training, School Training Portal, Gruvy Education, AI Training Access"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <FFNavbar />

      {/* Page Header */}
      <section className="ff-page-header ff-training-header">
        <div className="ff-container">
          <h1 className="ff-page-title">Training Portal</h1>
          <p className="ff-page-subtitle">
            Access your school's AI training materials
          </p>
        </div>
      </section>

      {/* School Selection Section */}
      <section className="ff-section ff-training-portal">
        <div className="ff-container">
          <div className="ff-training-instructions">
            <h2 className="ff-section-title">Select Your School</h2>
            
            {/* Search Bar */}
            <div className="ff-search-container">
              <input
                type="text"
                className="ff-search-input"
                placeholder="Search for your school..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Search for your school"
              />
              <svg className="ff-search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="currentColor" />
              </svg>
            </div>
          </div>
          
          {/* Schools Grid */}
          {loading ? (
            <div className="ff-loading-container">
              <div className="ff-loading-spinner"></div>
              <p>Loading schools...</p>
            </div>
          ) : filteredSchools.length > 0 ? (
            <div className="ff-schools-grid">
              {filteredSchools.map((school) => (
                <div
                  key={school.id}
                  className="ff-school-card"
                  onClick={() => navigateToSchoolTraining(school.id)}
                >
                  <div className="ff-school-icon">
                    <img 
                      src={imageErrors[school.id] ? Logo : (school.icon || Logo)} 
                      alt={`${school.Name} icon`} 
                      onError={() => handleImageError(school.id)}
                    />
                  </div>
                  <h3 className="ff-school-name">{school.Name}</h3>
                  <p className="ff-school-type">AI in Education Training</p>
                  <div className="ff-school-access-btn">
                    Access Training
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="ff-no-results">
              <p>No schools match your search. Please try a different search term.</p>
            </div>
          )}
        </div>
      </section>

      {/* School Not Listed Section */}
      <section className="ff-section ff-not-listed">
        <div className="ff-container">
          <h2 className="ff-section-title">Don't See Your School?</h2>
          <p className="ff-section-description">
            If your school has received training but isn't listed above, or if you're interested in bringing AI training to your school, please contact us.
          </p>
          <div className="ff-cta-buttons">
            <a
              href="https://calendly.com/gruvyeducation/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="ff-btn ff-btn-primary"
            >
              Book a Consultation
            </a>
            <a
              href="mailto:support@gruvyeducation.com"
              className="ff-btn ff-btn-secondary"
            >
              Email Support
            </a>
          </div>
        </div>
      </section>

      <FFFooter />
    </div>
  );
};

export default FFTrainingPortal;