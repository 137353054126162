import React from "react";
import Logo from "../../images/logo-no-background.png";

const AnalyzeStudentData = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(to bottom, #f9fafb, #e5e7eb)", // Soft gradient
        padding: "20px",
      }}
    >
      {/* Wrapper for Logo and Embed to Control Positioning */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start", // Aligns the logo to the left
          maxWidth: "600px",
          width: "100%",
        }}
      >
        {/* Logo - Left Aligned */}
        {/* <img
          src={Logo}
          alt="Your Logo"
          style={{
            maxWidth: "60px", // Smaller logo
            marginBottom: "10px",
            alignSelf: "center", // Keeps it positioned at the left of the container
          }}
        /> */}

        {/* Subscription Form - Centered */}
        <iframe
          src="https://embeds.beehiiv.com/0b8a03b3-5ba2-4323-aab3-6269df5dea67"
          data-test-id="beehiiv-embed"
          width="100%"
          height="320"
          frameBorder="0"
          scrolling="no"
          style={{
            borderRadius: "8px",
            border: "2px solid #d1d5db",
            backgroundColor: "transparent",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
          }}
          title="Subscribe to Newsletter"
        ></iframe>
      </div>
    </div>
  );
};

export default AnalyzeStudentData;
