import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FFNavbar from "./FFNavbar";
import FFFooter from "./FFFooter";
import FFTestimonialCarousel from "./FFTestimonialCarousel";

import WilliamPhoto from "../../images/william-vegas.jpeg";

const FFAbout = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="ff-home ff-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          About Gruvy Education | Trusted by 200+ Schools for AI Training
        </title>
        <meta
          name="description"
          content="Practical AI training from William Grube that's reached 200+ schools nationwide. Our 'Best PD in 20 years' helps teachers save time and reduce burnout."
        />
        <meta
          name="keywords"
          content="William Grube AI training, school AI training company, teacher AI professional development, trusted AI education experts, practical AI school implementation"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="About Gruvy Education | Trusted by 200+ Schools for AI Training"
        />
        <meta
          property="og:description"
          content="Practical AI training from William Grube that's reached 200+ schools nationwide. Our 'Best PD in 20 years' helps teachers save time and reduce burnout."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gruvyeducation.com/about" />
      </Helmet>

      <FFNavbar />

      {/* Hero Section */}
      <section className="ff-page-header ff-about-header">
        <div className="ff-container">
          <h1 className="ff-page-title">Our Mission</h1>
          <p className="ff-page-subtitle">
            We help teachers use AI tools with confidence
          </p>
        </div>
      </section>

      {/* Mission Section */}
      <section className="ff-section ff-about-mission">
        <div className="ff-container">
          <div className="ff-mission-content">
            <h2 className="ff-section-title">What We Do</h2>
            <p className="ff-mission-text">
              We train teachers to use AI tools that work in real classrooms
              today. Your teachers leave our training ready to use AI{" "}
              <b>right away.</b>
            </p>
            <br />
            <p className="ff-mission-text">
              School leaders tell us our training{" "}
              <b>opens staff eyes to AI benefits while reducing fears.</b>{" "}
              Teachers start speaking the same language about AI.
            </p>
            <br />
            <p className="ff-mission-text">
              We skip tech jargon and focus on practical tools.{" "}
              <b>Your teachers get back their most valuable resource: time.</b>
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="ff-section ff-testimonials">
        <div className="ff-container">
          <FFTestimonialCarousel
            maxVisible={2}
            maxTotal={6}
            featuredOnly={true}
            title="What Leaders Say"
          />
        </div>
      </section>

      {/* Approach Section */}
      <section className="ff-section ff-about-approach">
        <div className="ff-container">
          <h2 className="ff-section-title">How We Work</h2>
          <div className="ff-approach-grid">
            <div className="ff-approach-item">
              <h3 className="ff-approach-title">Hands-On Learning</h3>
              <p className="ff-approach-description">
                Your staff learns by doing. They build skills they use the same
                day. No theory - just practical tools.
              </p>
            </div>
            <div className="ff-approach-item">
              <h3 className="ff-approach-title">All Staff Benefit</h3>
              <p className="ff-approach-description">
                From AI beginners to daily users, everyone gains new skills. We
                meet each teacher where they are.
              </p>
            </div>
            <div className="ff-approach-item">
              <h3 className="ff-approach-title">Ongoing Support</h3>
              <p className="ff-approach-description">
                Our training portal reinforces skills and provides updates as AI
                changes. The learning continues after we leave.
              </p>
            </div>
            <div className="ff-approach-item">
              <h3 className="ff-approach-title">Real Results</h3>
              <p className="ff-approach-description">
                Teachers save time. Student engagement increases. Communication
                improves. School operations run smoother.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Founder Section */}
      <section className="ff-section ff-about-founder">
        <div className="ff-container">
          <div className="ff-founder-grid">
            <div className="ff-founder-image">
              <img
                src={WilliamPhoto}
                alt="William Grube - Founder"
                className="ff-profile-image"
              />
            </div>
            <div className="ff-founder-bio">
              <h2 className="ff-section-title">
                About the Trainer: William Grube
              </h2>
              <p className="ff-bio-text">
                William Grube founded Gruvy Education to help schools use AI
                tools. He works with over 200 schools across the country.
              </p>
              <p className="ff-bio-text">
                School leaders call his training "the best in 20 years of
                education" and "truly eye-opening." William shows teachers how
                to use AI to improve teaching right now.
              </p>
              <p className="ff-bio-text">
                He earned his Computer Science degree in 2024 and helps make
                learning relevant for the 21st century.
              </p>
              <div className="ff-founder-cta">
                <a
                  href="https://usemotion.com/meet/william-grube/meeting"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ff-btn ff-btn-secondary"
                >
                  Talk to William
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Resources Section */}
      <section className="ff-section ff-about-resources">
        <div className="ff-container">
          <h2 className="ff-section-title">Free Resources</h2>
          <p className="ff-section-description">Start using these today:</p>
          <div className="ff-resources-grid">
            <Link to="/promptlibrary" className="ff-resource-card">
              <h3 className="ff-resource-title">AI Prompt Library</h3>
              <p className="ff-resource-description">
                Ready-to-use prompts for lesson plans, grading, and student
                support.
              </p>
              <span className="ff-resource-link">Get Prompts →</span>
            </Link>
            <Link to="/freeresources" className="ff-resource-card">
              <h3 className="ff-resource-title">Admin Tools</h3>
              <p className="ff-resource-description">
                Guides, policy blueprint, and planning tools for school leaders.
              </p>
              <span className="ff-resource-link">Get Tools →</span>
            </Link>
            <a
              href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe"
              target="_blank"
              rel="noopener noreferrer"
              className="ff-resource-card"
            >
              <h3 className="ff-resource-title">Admin Exclusive Newsletter</h3>
              <p className="ff-resource-description">
                Get the ways to use AI as a school leader.
              </p>
              <span className="ff-resource-link">Sign Up →</span>
            </a>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="ff-section ff-cta-section">
        <div className="ff-container">
          <h2 className="ff-section-title">Ready to Transform Your School?</h2>
          <p className="ff-section-description">
            Join 200+ schools that use our AI training to save time and improve
            teaching.
          </p>
          <div className="ff-cta-buttons">
            <Link to="/traininginfo" className="ff-btn ff-btn-primary">
              See Training Options
            </Link>
            <a
              href="https://calendly.com/gruvyeducation/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="ff-btn ff-btn-secondary"
            >
              Book a Call
            </a>
          </div>
        </div>
      </section>

      <FFFooter />
    </div>
  );
};

export default FFAbout;
