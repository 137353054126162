import React, { useState, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import db from "../../Firebase";
import FFNavbar from "./FFNavbar";
import FFFooter from "./FFFooter";

const FFTestimonials = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [showFloatingCTA, setShowFloatingCTA] = useState(false);

  // Show floating CTA after scrolling down a bit
  useEffect(() => {
    const handleScroll = () => {
      // Show CTA after scrolling down 500px
      if (window.scrollY > -1) {
        setShowFloatingCTA(true);
      } else {
        setShowFloatingCTA(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToCTA = (e) => {
    e.preventDefault();
    const ctaSection = document.querySelector(".ff-cta-section");
    if (ctaSection) {
      // Get the navbar height to offset the scroll position
      const navbar = document.querySelector("ffNav"); // Adjust this selector if your navbar has a different class/tag
      const navbarHeight = navbar ? navbar.offsetHeight : 205;

      // Get the position of the CTA section relative to the top of the page
      const ctaSectionPosition =
        ctaSection.getBoundingClientRect().top + window.pageYOffset;

      // Scroll to the CTA section with an offset for the navbar
      window.scrollTo({
        top: ctaSectionPosition - navbarHeight - 20, // 20px extra padding for visual comfort
        behavior: "smooth",
      });
    }
  };

  // Updating the useState for activeTab to use URL hash
  const [activeTab, setActiveTab] = useState(() => {
    // Get the hash from URL without the # symbol
    const hash = window.location.hash.substring(1);

    // Valid tab names
    const validTabs = [
      "caseStudies",
      "leaderTestimonials",
      "teacherTestimonials",
    ];

    // Return the hash if it's a valid tab name, otherwise default to "caseStudies"
    return validTabs.includes(hash) ? hash : "caseStudies";
  });

  // Add this effect to handle hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const validTabs = [
        "caseStudies",
        "leaderTestimonials",
        "teacherTestimonials",
      ];

      if (validTabs.includes(hash)) {
        setActiveTab(hash);
      }
    };

    // Add event listener
    window.addEventListener("hashchange", handleHashChange);

    // Clean up
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // State for the data
  const [caseStudies, setCaseStudies] = useState([]);
  const [leaderTestimonials, setLeaderTestimonials] = useState([]);
  const [teacherTestimonials, setTeacherTestimonials] = useState([]);

  // State for loading
  const [loading, setLoading] = useState(true);

  // Fetch data from Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch case studies ordered by training date (newest first)
        const caseStudiesQuery = query(
          collection(db, "caseStudies"),
          orderBy("trainingDate", "desc")
        );
        const caseStudiesSnapshot = await getDocs(caseStudiesQuery);
        const caseStudiesData = caseStudiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCaseStudies(caseStudiesData);

        // Fetch leader testimonials ordered by rank
        const leaderQuery = query(
          collection(db, "leaderTestimonials"),
          orderBy("rank", "asc")
        );
        const leaderSnapshot = await getDocs(leaderQuery);
        const leaderData = leaderSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLeaderTestimonials(leaderData);

        // Fetch teacher testimonials ordered by rank
        const teacherQuery = query(
          collection(db, "teacherTestimonials"),
          orderBy("rank", "asc")
        );
        const teacherSnapshot = await getDocs(teacherQuery);
        const teacherData = teacherSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTeacherTestimonials(teacherData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Format date from timestamp to MM/DD/YYYY
  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "Not specified";
    }

    // Handle Firebase Timestamp objects
    if (timestamp && typeof timestamp.toDate === "function") {
      // This is a Firebase Timestamp
      const date = timestamp.toDate();
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    // Handle Unix timestamps (numbers)
    if (typeof timestamp === "number") {
      const date = new Date(timestamp);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    // Handle string dates or date objects
    try {
      const date = new Date(timestamp);
      if (!isNaN(date.getTime())) {
        // Check if date is valid
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      }
    } catch (error) {
      console.error("Error formatting date:", error);
    }

    return "Invalid date";
  };

  // Extract only the iframe from the embed code, discarding the outer div and attribution
  const extractIframe = (embedCode) => {
    const iframeMatch = embedCode.match(/<iframe[^>]*>[\s\S]*?<\/iframe>/);
    return iframeMatch ? iframeMatch[0] : "";
  };

  // Render case studies
  const renderCaseStudies = () => {
    if (caseStudies.length === 0) {
      return <p className="ff-empty-state">Case studies coming soon!</p>;
    }

    return (
      <div className="ff-case-studies-grid">
        {caseStudies.map((study) => (
          <div key={study.id} className="ff-case-study-card">
            <div className="ff-case-study-header">
              <h3 className="ff-case-study-title">
                {study.title || `${study.schoolName} Case Study`}
              </h3>
              <p className="ff-case-study-date">
                Training Date: {formatDate(study.trainingDate)}
              </p>
            </div>

            <div className="ff-case-study-embed-container">
              <div
                className="ff-case-study-embed"
                dangerouslySetInnerHTML={{
                  __html: extractIframe(study.embedCode),
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Render leader testimonials
  const renderLeaderTestimonials = () => {
    if (leaderTestimonials.length === 0) {
      return <p className="ff-empty-state">Leader testimonials coming soon!</p>;
    }

    return (
      <div className="ff-testimonials-grid">
        {leaderTestimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`ff-testimonial-card ${
              testimonial.featured ? "ff-testimonial-featured" : ""
            }`}
          >
            <p className="ff-testimonial-text">{testimonial.testimonial}</p>
            <div className="ff-testimonial-author-info">
              <p className="ff-testimonial-author">{testimonial.name}</p>
              <p className="ff-testimonial-position">
                {testimonial.position}, {testimonial.schoolName}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Render teacher testimonials
  const renderTeacherTestimonials = () => {
    if (teacherTestimonials.length === 0) {
      return (
        <p className="ff-empty-state">Teacher testimonials coming soon!</p>
      );
    }

    return (
      <div className="ff-testimonials-grid">
        {teacherTestimonials.map((testimonial) => (
          <div key={testimonial.id} className="ff-testimonial-card">
            <p className="ff-testimonial-text">{testimonial.testimonial}</p>
            <div className="ff-testimonial-author-info">
              <p className="ff-testimonial-school">
                Teacher from {testimonial.schoolName}
                {testimonial.subject ? ` • ${testimonial.subject}` : ""}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Render content based on active tab
  const renderContent = () => {
    if (loading) {
      return <div className="ff-loading">Loading testimonials...</div>;
    }

    switch (activeTab) {
      case "caseStudies":
        return renderCaseStudies();
      case "leaderTestimonials":
        return renderLeaderTestimonials();
      case "teacherTestimonials":
        return renderTeacherTestimonials();
      default:
        return null;
    }
  };

  return (
    <div className="ff-home ff-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Real School Results with AI PD | 90% Teacher Satisfaction Guaranteed
        </title>
        <meta
          name="description"
          content="'Best PD in 20 years of education.' See how 150+ schools saved teachers 3+ hours weekly with AI training that actually sticks. Includes leader and teacher testimonials."
        />
        <meta
          name="keywords"
          content="teacher PD that works, evidence-based professional development, AI training success stories, proven teacher time savings, school leader testimonials"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Real School Results with AI PD | 90% Teacher Satisfaction Guaranteed"
        />
        <meta
          property="og:description"
          content="'Best PD in 20 years of education.' See how 150+ schools saved teachers 3+ hours weekly with AI training that actually sticks."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://gruvyeducation.com/testimonials"
        />
      </Helmet>

      <FFNavbar />

      {/* Floating CTA Button */}
      <div
        className={`ff-floating-cta ${
          showFloatingCTA ? "ff-floating-cta-visible" : ""
        }`}
      >
        <button
          onClick={scrollToCTA}
          className="ff-btn ff-btn-primary ff-floating-btn"
        >
          Schedule Training
        </button>
      </div>

      {/* Page Header */}
      <section className="ff-page-header ff-testimonials-header">
        <div className="ff-container">
          <h1 className="ff-page-title">Success Stories</h1>
          <p className="ff-page-subtitle">
            See how our AI training transforms schools
          </p>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="ff-section ff-testimonials-content">
        <div className="ff-container">
          {/* Toggle Navigation */}
          <div className="ff-testimonials-toggle">
            <button
              className={`ff-toggle-btn ${
                activeTab === "caseStudies" ? "ff-toggle-active" : ""
              }`}
              onClick={() => {
                setActiveTab("caseStudies");
                window.history.pushState(null, "", "#caseStudies");
              }}
            >
              Case Studies
            </button>

            <button
              className={`ff-toggle-btn ${
                activeTab === "leaderTestimonials" ? "ff-toggle-active" : ""
              }`}
              onClick={() => {
                setActiveTab("leaderTestimonials");
                window.history.pushState(null, "", "#leaderTestimonials");
              }}
            >
              School Leader Testimonials
            </button>

            <button
              className={`ff-toggle-btn ${
                activeTab === "teacherTestimonials" ? "ff-toggle-active" : ""
              }`}
              onClick={() => {
                setActiveTab("teacherTestimonials");
                window.history.pushState(null, "", "#teacherTestimonials");
              }}
            >
              Teacher Testimonials
            </button>
          </div>

          {/* Tab Description */}
          {/* <div className="ff-tab-description">
            {activeTab === 'caseStudies' && (
              <p>Case studies from every school who has recieved the training:</p>
            )}
            {activeTab === 'leaderTestimonials' && (
              <p>What school leaders say about our AI training impact:</p>
            )}
            {activeTab === 'teacherTestimonials' && (
              <p>What teachers say about our AI training:</p>
            )}
          </div> */}

          {/* Content Area */}
          <div className="ff-testimonials-content-area">{renderContent()}</div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="ff-section ff-cta-section" id="contact">
        <div className="ff-container">
          <h2 className="ff-section-title">
            Ready to See These Results in Your School?
          </h2>
          <p className="ff-section-description">
            Book a 15-minute call to discuss how AI training can work for your
            staff and students. Remember: If less than 90% of your teachers
            would recommend this training to their colleagues, you don't pay.
          </p>
          <div className="ff-cta-buttons">
            <a
              href="https://usemotion.com/meet/william-grube/meeting?d=30"
              target="_blank"
              rel="noopener noreferrer"
              className="ff-btn ff-btn-primary"
            >
              Schedule a 15-Min Call
            </a>
            <span className="ff-cta-separator">or</span>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                const contactFormSection =
                  document.querySelector(".ff-footer-contact");
                if (contactFormSection) {
                  contactFormSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="ff-btn ff-btn-secondary"
            >
              Contact us for Information
            </a>
          </div>
        </div>
      </section>

      <FFFooter />
    </div>
  );
};

export default FFTestimonials;
