import React, { useState } from 'react';

const VideoCard = ({
  id,
  title,
  description,
  url,
  thumbnailUrl,
  dateAdded,
  dateAddedTimestamp,
  clickCount = 0,
  collection,
  featured = false,
  onTrackClick
}) => {
  // Add error state for image loading
  const [imageError, setImageError] = useState(false);

  // Function to handle video click
  const handleVideoClick = () => {
    // Use the callback prop instead of importing the service
    if (onTrackClick) {
      onTrackClick();
    }
  };

  // Format date to readable format
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    // Handle different timestamp formats
    const milliseconds = timestamp?.seconds 
      ? timestamp.seconds * 1000 
      : (typeof timestamp === 'number' ? timestamp : null);
    
    if (!milliseconds) return '';
    
    const date = new Date(milliseconds);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Extract YouTube video ID
  const getYoutubeVideoId = (youtubeUrl) => {
    if (!youtubeUrl) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = youtubeUrl.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  // Get thumbnail URL
  const getThumbnail = () => {
    if (thumbnailUrl) return thumbnailUrl;
    
    const videoId = getYoutubeVideoId(url);
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    }
    
    // Fallback image if no thumbnail is available
    return 'https://via.placeholder.com/640x360?text=Video';
  };

  // Handle image loading errors
  const handleImageError = () => {
    setImageError(true);
  };

  // Safeguard against missing required props
  if (!title || !url) {
    return null;
  }

  return (
    <div className={`ff-resource-card ff-video-card ${featured ? 'ff-resource-featured' : ''}`}>
      <a 
        href={url} 
        target="_blank" 
        rel="noopener noreferrer"
        onClick={handleVideoClick}
        className="ff-video-link"
        aria-label={`Watch video: ${title}`}
      >
        <div className="ff-video-thumbnail">
          <img 
            src={imageError ? 'https://via.placeholder.com/640x360?text=Video' : getThumbnail()} 
            alt={title} 
            loading="lazy" 
            onError={handleImageError}
          />
          <div className="ff-video-play">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polygon points="5 3 19 12 5 21 5 3"></polygon>
            </svg>
          </div>
        </div>
      </a>
      
      <div className="ff-resource-content">
        <h3 className="ff-resource-title">{title}</h3>
        {description && <p className="ff-resource-description">{description}</p>}
        
        <div className="ff-resource-meta">
          <span className="ff-resource-type">VIDEO</span>
          {(dateAdded || dateAddedTimestamp) && (
            <span className="ff-resource-date">
              Added: {formatDate(dateAddedTimestamp || dateAdded)}
            </span>
          )}
          {clickCount > 0 && (
            <span className="ff-resource-clicks">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              {/* {clickCount} {clickCount === 1 ? 'view' : 'views'} */}
            </span>
          )}
          {featured && (
            <span className="ff-resource-featured-tag">Featured</span>
          )}
        </div>
        
        <a 
          href={url} 
          target="_blank" 
          rel="noopener noreferrer"
          className="ff-resource-link"
          onClick={handleVideoClick}
        >
          Watch Video
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
            <polyline points="15 3 21 3 21 9"></polyline>
            <line x1="10" y1="14" x2="21" y2="3"></line>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default VideoCard;