import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Logo from "../../images/logo-no-background.png";
import Hermantown from "../../images/Hermantown.png";
import FFNavbar from "./FFNavbar";
import FFFooter from "./FFFooter";
import FFTestimonialCarousel from "./FFTestimonialCarousel";

const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="ff-home ff-font">
      {/* SEO Metadata */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gruvy Education | Practical AI Training for K-12 Schools</title>
        <meta
          name="description"
          content="AI training that saves teachers 3+ hours weekly with 90% satisfaction. Tool-focused, not theory — exactly what busy K-12 educators need to reduce burnout."
        />
        <meta
          name="keywords"
          content="Save teacher time, Teacher burnout solutions, AI professional development, K-12 staff training, Teacher productivity tools"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Gruvy Education | Practical AI Training for K-12 Schools"
        />
        <meta
          property="og:description"
          content="AI training that saves teachers 3+ hours weekly with 90% satisfaction. No theory, just tools that work."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://gruvyeducation.com" />
      </Helmet>

      {/* Navigation */}
      <FFNavbar />

      {/* Hero Section */}
      <div className="ff-hero">
        <div className="ff-container">
          <h1 className="ff-hero-title">
            AI Training Teachers Actually Use — Saves Each Teacher 100+ Hours
            Per Year
          </h1>
          <p className="ff-hero-subtitle">
            Trusted by 150+ schools to deliver practical tools teachers actually
            use — not theory they ignore.
          </p>
          <div className="ff-hero-buttons">
            <Link to="/testimonials" className="ff-btn ff-btn-primary">
              See Proven Results
            </Link>
            <a
              target="_blank"
              href="https://usemotion.com/meet/william-grube/meeting"
              className="ff-btn ff-btn-secondary"
            >
              Book a 15-Min Call
            </a>
          </div>
        </div>
      </div>

      {/* Quick Access Navigation */}
      <section className="ff-section ff-quick-access">
        <div className="ff-container">
          <div className="ff-quick-access-grid">
            <Link to="/training" className="ff-quick-access-item">
              <div className="ff-quick-access-content">
                <h3 className="ff-quick-access-title">Training Portal</h3>
                <p className="ff-quick-access-description">
                  Ongoing support for our partnered schools
                </p>
              </div>
            </Link>
            <Link to="/promptlibrary" className="ff-quick-access-item">
              <div className="ff-quick-access-content">
                <h3 className="ff-quick-access-title">Prompt Library</h3>
                <p className="ff-quick-access-description">
                  100+ ready-to-use teacher prompts
                </p>
              </div>
            </Link>
            <Link to="/freeresources#leaders" className="ff-quick-access-item">
              <div className="ff-quick-access-content">
                <h3 className="ff-quick-access-title">Admin Resources</h3>
                <p className="ff-quick-access-description">
                  Tools leaders use today, not theory
                </p>
              </div>
            </Link>
            <a
              href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe"
              className="ff-quick-access-item"
              target="_blank"
            >
              <div className="ff-quick-access-content">
                <h3 className="ff-quick-access-title">
                  School Leader Newsletter
                </h3>
                <p className="ff-quick-access-description">
                  1-minute reads, massive impact
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>

      {/* Problem Section */}
      <section className="ff-section ff-problem">
        <div className="ff-container ff-split">
          <div className="ff-text">
            <h2 className="ff-section-title">
              Your Teachers Deserve PD That Saves Time, Not Creates More Work
            </h2>
            <p className="ff-section-description">
              While many educators still see AI as just a student cheating tool,
              forward-thinking schools are helping their teachers reclaim 3+
              hours weekly with practical AI strategies. Give your staff the
              same advantage—without the frustrating learning curve.
            </p>
          </div>
          <div className="ff-graphic">
            <img src={Hermantown} alt="AI in Education" className="ff-image" />
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="ff-section ff-solution">
        <div className="ff-container">
          <h2 className="ff-section-title">
            Why Leaders Choose Gruvy: Results, Not Just Talk
          </h2>
          <div className="ff-columns">
            <div className="ff-column">
              <h3 className="ff-column-title">
                Built For Educators, Not Techies
              </h3>
              <p className="ff-column-description">
                Even your most tech-resistant teachers leave ready to use AI on
                day one.
              </p>
            </div>
            <div className="ff-column">
              <h3 className="ff-column-title">3+ Hours Saved Weekly</h3>
              <p className="ff-column-description">
                Teachers immediately reclaim prep time with our practical
                training.
              </p>
            </div>
            <div className="ff-column">
              <h3 className="ff-column-title">Ethical & Responsible</h3>
              <p className="ff-column-description">
                Addresses privacy, student data, and district compliance
                head-on.
              </p>
            </div>
            <div className="ff-column">
              <h3 className="ff-column-title">Guaranteed Results</h3>
              <p className="ff-column-description">
                If less than 90% of your staff would recommend the training to
                other educators, you don’t pay.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="ff-section ff-testimonials">
        <div className="ff-container">
          <FFTestimonialCarousel
            maxVisible={2}
            maxTotal={10}
            featuredOnly={true}
            title="From Skeptical to Believers: School Leader Testimonials"
          />
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="ff-section ff-cta-section">
        <div className="ff-container">
          <h2 className="ff-section-title">
            Give Your Teachers the AI Advantage Today
          </h2>
          <p className="ff-section-description">
            Equip your staff with time-saving tools that make their jobs easier
            and their impact greater. If less than 90% of your staff would
            recommend the training to other educators, you don’t pay.
          </p>
          <div className="ff-cta-buttons">
            <a
              href="/trainingportalapplication"
              className="ff-btn ff-btn-primary"
              target="_blank"
            >
              Transform My Staff
            </a>
            {/* <Link to="/training" className="ff-btn ff-btn-secondary">
              Access Training Portal
            </Link> */}
          </div>
        </div>
      </section>

      {/* Free Resources Section */}
      <section className="ff-section ff-resources">
        <div className="ff-container">
          <h2 className="ff-section-title">Free Resources You Can Use Today</h2>
          <p className="ff-section-description">
            Don't wait to give your teachers the support they need. Start with
            these free tools while we discuss your complete training plan.
          </p>
          <div className="ff-resources-grid">
            <Link to="/freeresources#leaders" className="ff-resource-card">
              <h3 className="ff-resource-title">Administrator Toolkit</h3>
              <p className="ff-resource-description">
                Immediate tools for managing AI in your school
              </p>
            </Link>
            <Link to="/freeresources#teachers" className="ff-resource-card">
              <h3 className="ff-resource-title">Teacher Toolkit</h3>
              <p className="ff-resource-description">
                Immediate tools for teachers
              </p>
            </Link>
            <a
              href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe"
              target="_blank"
              className="ff-resource-card"
            >
              <h3 className="ff-resource-title">Weekly AI Leadership Minute</h3>
              <p className="ff-resource-description">
                Quick reads that keep you ahead of the curve
              </p>
            </a>
            <Link to="/promptlibrary" className="ff-resource-card">
              <h3 className="ff-resource-title">Teacher Prompt Library</h3>
              <p className="ff-resource-description">
                Copy-paste prompts teachers can use today
              </p>
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <FFFooter />
    </div>
  );
};

export default Home;
