import React, { useState, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  doc,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import db from "../../Firebase";
import FFNavbar from "./FFNavbar";
import FFFooter from "./FFFooter";

const FFPromptLibrary = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  // State for prompt library
  const [prompts, setPrompts] = useState([]);
  const [categories, setCategories] = useState(["All Prompts"]);
  const [selectedCategory, setSelectedCategory] = useState("All Prompts");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch prompts and categories on component mount
  useEffect(() => {
    const fetchPromptsAndCategories = async () => {
      setLoading(true);
      try {
        // Get categories
        const categoriesDoc = await getDoc(doc(db, "Prompts", "000categories"));
        if (categoriesDoc.exists()) {
          const categoriesData = categoriesDoc.data();
          // Extract categories and sort them alphabetically
          const sortedCategories = Object.keys(
            categoriesData.AllCategories
          ).sort((a, b) => a.localeCompare(b));
          // Prepend 'All Prompts' to the sorted categories
          setCategories(["All Prompts", ...sortedCategories]);
        }

        // Get prompts
        const promptsRef = collection(db, "Prompts");
        const promptsSnapshot = await getDocs(promptsRef);
        const promptsList = promptsSnapshot.docs
          .filter((doc) => doc.id !== "000categories") // Exclude the categories document
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

        setPrompts(promptsList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching prompts and categories:", err);
        setError("Failed to load prompt library. Please try again later.");
        setLoading(false);
      }
    };

    fetchPromptsAndCategories();
  }, []);

  // Track prompt click
  const trackPromptClick = async (promptId) => {
    try {
      const promptRef = doc(db, "Prompts", promptId);
      await updateDoc(promptRef, {
        clicks: increment(1),
      });
    } catch (err) {
      console.error("Error tracking prompt click:", err);
    }
  };

  // Category dropdown component
  const PromptCategoryDropdown = () => {
    const [isActive, setIsActive] = useState(false);

    return (
      <div className="ff-prompt-dropdown-centered">
        <h3 className="ff-prompt-dropdown-label">Select a Prompt Category</h3>
        <div className="ff-dropdown-container">
          <button
            className={`ff-dropdown-button-large ${isActive ? "active" : ""}`}
            onClick={() => setIsActive(!isActive)}
          >
            {selectedCategory}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>

          {isActive && (
            <div className="ff-dropdown-menu-large">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className="ff-dropdown-item"
                  onClick={() => {
                    setSelectedCategory(category);
                    setIsActive(false);
                  }}
                >
                  {category}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="ff-home ff-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          100+ Ready-to-Use ChatGPT Prompts for Teachers | Copy-Paste & Save
          Time
        </title>
        <meta
          name="description"
          content="Free copy-paste prompts for lesson planning, differentiation, feedback, and grading. Organized by subject area and task. Save 2+ hours weekly on routine teacher tasks."
        />
        <meta
          name="keywords"
          content="ChatGPT prompts for teachers, teacher time-saving prompts, AI lesson planning templates, differentiation prompts, teacher feedback prompts, grading prompts for educators"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="100+ Ready-to-Use ChatGPT Prompts for Teachers | Copy-Paste & Save Time"
        />
        <meta
          property="og:description"
          content="Free copy-paste prompts for lesson planning, differentiation, feedback, and grading. Save 2+ hours weekly on routine teacher tasks."
        />
        <meta
          property="og:url"
          content="https://gruvyeducation.com/promptlibrary"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <FFNavbar />

      <section className="ff-page-header ff-resources-header">
        <div className="ff-container">
          <h1 className="ff-page-title">Educator Prompt Library</h1>
          <p className="ff-page-subtitle">
            Ready-to-use prompts for educators to leverage AI effectively in the
            classroom
          </p>
        </div>
      </section>

      <section className="ff-section no-padding">
        <div className="ff-container">
          {loading ? (
            <div className="ff-resources-placeholder">
              <div className="ff-loading-spinner"></div>
              <p>Loading prompt library...</p>
            </div>
          ) : error ? (
            <div className="ff-resources-placeholder ff-error">
              <p>{error}</p>
              <button
                onClick={() => window.location.reload()}
                className="ff-btn ff-btn-secondary ff-btn-sm"
              >
                Try Again
              </button>
            </div>
          ) : prompts.length > 0 ? (
            <>
              <div className="ff-prompt-controls-centered">
                <PromptCategoryDropdown />
              </div>

              <div className="ff-prompts-grid">
                {prompts
                  .filter(
                    (prompt) =>
                      prompt.Category === selectedCategory ||
                      selectedCategory === "All Prompts"
                  )
                  .sort((a, b) => {
                    if (a.Category < b.Category) return -1;
                    if (a.Category > b.Category) return 1;
                    return 0;
                  })
                  .map((prompt) => (
                    <a
                      key={prompt.id}
                      href={prompt.Link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ff-prompt-card"
                      onClick={() => trackPromptClick(prompt.id)}
                    >
                      <div className="ff-prompt-content">
                        <div className="ff-prompt-title-wrapper">
                          <h3 className="ff-prompt-title">{prompt.Title}</h3>
                        </div>
                        <div className="ff-prompt-category-badge">
                          {prompt.Category}
                        </div>
                        <div className="ff-prompt-card-footer">
                          <span className="ff-prompt-view-text">
                            View Prompt
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="7" y1="17" x2="17" y2="7"></line>
                            <polyline points="7 7 17 7 17 17"></polyline>
                          </svg>
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </>
          ) : (
            <div className="ff-resources-placeholder">
              <p>No prompts available at this time. Please check back soon!</p>
            </div>
          )}
        </div>
      </section>

      <FFFooter />
    </div>
  );
};

export default FFPromptLibrary;
