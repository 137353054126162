import React from 'react';

const ResourceCard = ({ 
  id, 
  title, 
  description, 
  type, 
  url, 
  dateAdded,
  dateAddedTimestamp,
  clickCount = 0,
  collection,
  featured = false,
  onTrackClick
}) => {
  // Function to handle resource click
  const handleResourceClick = (e) => {
    // Use the callback prop instead of importing the service
    if (onTrackClick) {
      onTrackClick();
    }
  };

  // Get appropriate icon based on resource type
  const getResourceIcon = () => {
    if (!type) return defaultIcon();
    
    switch(type.toLowerCase()) {
      case 'pdf':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <line x1="10" y1="9" x2="8" y2="9"></line>
          </svg>
        );
      case 'doc':
      case 'docx':
      case 'document':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
        );
      case 'sheets':
      case 'xlsx':
      case 'xls':
      case 'spreadsheet':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="3" y1="9" x2="21" y2="9"></line>
            <line x1="3" y1="15" x2="21" y2="15"></line>
            <line x1="9" y1="3" x2="9" y2="21"></line>
            <line x1="15" y1="3" x2="15" y2="21"></line>
          </svg>
        );
      case 'slides':
      case 'ppt':
      case 'pptx':
      case 'presentation':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
            <line x1="8" y1="21" x2="16" y2="21"></line>
            <line x1="12" y1="17" x2="12" y2="21"></line>
          </svg>
        );
      case 'video':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polygon points="23 7 16 12 23 17 23 7"></polygon>
            <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
          </svg>
        );
      case 'link':
      default:
        return defaultIcon();
    }
  };
  
  // Default icon for links
  const defaultIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
      </svg>
    );
  };

  // Format date to readable format
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    // Handle different timestamp formats
    const milliseconds = timestamp?.seconds 
      ? timestamp.seconds * 1000 
      : (typeof timestamp === 'number' ? timestamp : null);
    
    if (!milliseconds) return '';
    
    const date = new Date(milliseconds);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Safeguard against missing required props
  if (!title || !url) {
    return null;
  }

  return (
    <a 
      href={url} 
      target="_blank" 
      rel="noopener noreferrer"
      onClick={handleResourceClick}
      className={`ff-resource-card ${featured ? 'ff-resource-featured' : ''}`}
      aria-label={`View resource: ${title}`}
    >
      <div className="ff-resource-card-inner">
        <div className="ff-resource-icon">
          {getResourceIcon()}
        </div>
        
        <div className="ff-resource-content">
          <h3 className="ff-resource-title">{title}</h3>
          {description && <p className="ff-resource-description">{description}</p>}
          
          <div className="ff-resource-meta">
            {type && <span className="ff-resource-type">{type.toUpperCase()}</span>}
            {(dateAdded || dateAddedTimestamp) && (
              <span className="ff-resource-date">
                Added: {formatDate(dateAddedTimestamp || dateAdded)}
              </span>
            )}
            {clickCount > 0 && (
              <span className="ff-resource-clicks">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
                {clickCount} {clickCount === 1 ? 'view' : 'views'}
              </span>
            )}
            {featured && (
              <span className="ff-resource-featured-tag">Featured</span>
            )}
          </div>
          
          <div className="ff-resource-link">
            View Resource
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
              <polyline points="15 3 21 3 21 9"></polyline>
              <line x1="10" y1="14" x2="21" y2="3"></line>
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ResourceCard;